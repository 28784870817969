import moment from 'moment';
import * as constStr from './Constants';

export const getMarkerLabel = (id, vehicles) => {
  const vehicle = vehicles.filter((vehicle) => vehicle.id === id);

  if (vehicle.length > 0) {
    return {
      name: vehicle[0].name,
      category: vehicle[0].category,
      status: vehicle[0].status
    };
  }
  return;
};

export const getFillColor = (v) => {
  return v && v.status === 'online'
    ? '#008000'
    : v.status === 'offline'
    ? '#ff1500'
    : '#ff9800';
};

export const getCategoryImage = (v) => {
  let categoryImage = '';
  if (v) {
    switch (v.category) {
      case 'offroad':
        categoryImage = 'car.svg';
        break;
      case 'boat':
        categoryImage = 'boat.svg';
        break;
      default:
        categoryImage = 'user.svg';
    }
  }
  return categoryImage;
};

export const getUTCDate = (d) => {
  let m = d ? moment(d) : moment();
  const from = m.toISOString();
  const to = moment(m).add(1, 'd').toISOString();

  const r = {
    from,
    to
  };

  return r;
};

export const getISOOffSetToday = (d) => {
  let m = d ? moment(d) : moment();
  const date = m.format('YYYY-MM-DD');
  const tDate = moment(date, 'YYYY-MM-DD')
    .subtract(1, 'd')
    .format('YYYY-MM-DD');

  const r = {
    from: tDate + 'T16:00:00Z',
    to: date + 'T15:59:59Z'
  };
  return r;
};

export const getISOOffSet = (args) => {
  const tDate = moment(args.from, 'YYYY-MM-DD')
    .subtract(1, 'd')
    .format('YYYY-MM-DD');
  const r = {
    from: tDate + 'T16:00:00Z',
    to: args.to + 'T15:59:59Z'
  };
  return r;
};

export const getISOOffsetByTime = (args) => {
  const tDate = moment(args.time, 'YYYY-MM-DD')
    .subtract(8, 'h')
    .format('YYYY-MM-DD');

  return tDate;
};

export const getFormatPlusTime = (args) => {
  const tDate = moment(args.time).format('YYYY-MM-DD hh:mm:ss A');

  return tDate;
};

export const getISOOffsetByFormat = (args) => {
  const tDate = moment(args.time, args.format)
    .subtract(8, 'h')
    .format(args.format);

  return tDate;
};

export const getDaysHoursMinutes = (args) => {
  let type = 'm';
  if (args.count > 60) {
    type = 'h';
  } else if (args.count > 3600) {
    type = 'd';
  }

  return type;
};

export const getDuration = (args) => {
  const now = moment();
  const end = moment(args.date);
  const duration = moment.duration(end.diff(now));

  return duration.humanize();
};

export const sortByTimeDesc = (arr) => {
  const res = arr.sort((a, b) =>
    moment(a.fixTime).isBefore(b.fixTime) ? 1 : -1
  );
  return res;
};

export const sortByStartTimeDesc = (arr) => {
  return arr.sort((a, b) =>
    moment(a.startTime).isBefore(b.startTime) ? 1 : -1
  );
};

const generateSvg = (v) => {
  const svg = `<?xml version="1.0" encoding="UTF-8"?>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="35"
        height="35"
      >
        <circle
          cx="17"
          cy="17"
          r="14"
          stroke="#fff"
          strokeWidth="2.5"
          fill="${getFillColor(v)}"
        />
        <image
          x="7    "
          y="6"
          width="20"
          height="20"
          fill="#fff"
          href="/category/${getCategoryImage(v)}"
        />
      </svg>`;
  return svg;
};

export const createLandmarkContent = (landmark) => {
  let str = '';

  str += landmark.distanceInMeter > 0 ? `${landmark.distanceInMeter}m ` : '';
  str += landmark.distanceInMeter > 0 ? 'From' : '';
  str +=
    landmark.direction && landmark.distanceInMeter > 0
      ? `${landmark.direction} `
      : '';
  str += ` ${landmark.landmarkName}`;

  return str;
};

export const createPlaceContent = (place) => {
  let str = '(';

  str += place.distanceInKilometer > 0 ? `${place.distanceInKilometer}km ` : '';
  str +=
    place.direction && place.distanceInKilometer > 0
      ? `${place.direction} `
      : '';
  str += place.distanceInKilometer > 0 ? 'From' : '';
  str += ` ${place.place}`;
  str += ')';
  return str;
};

export const createIcon = (v) => {
  const icon = new Image();
  icon.src = 'data:image/svg+xml;charset=utf-8;base64,' + btoa(generateSvg(v));

  return icon;
};

export const getGpxStatus = (v) => {
  const {
    STOPPED,
    OFFLINE,
    MOVING,
    ONLINE,
    UNKNOWN,
    LAST_UPDATE_THRESHOLD,
    SPEED_THRESHOLD
  } = constStr;
  let status = OFFLINE;
  if (v.lastUpdateInMinutes <= LAST_UPDATE_THRESHOLD) {
    if (
      (v.status === ONLINE &&
        v.position &&
        v.position.attributes &&
        v.position.attributes.motion) ||
      (v.status === OFFLINE &&
        v.position &&
        v.position.attributes.motion &&
        v.position.speed > SPEED_THRESHOLD &&
        v.lastUpdateInMinutes <= LAST_UPDATE_THRESHOLD)
    ) {
      status = MOVING;
    } else if (
      (v.status === ONLINE &&
        v.position &&
        v.position.attributes &&
        !v.position.attributes.motion) ||
      (v.status === OFFLINE &&
        v.position &&
        v.position.attributes &&
        !v.position.attributes.motion &&
        v.position.valid &&
        v.lastUpdateInMinutes <= LAST_UPDATE_THRESHOLD)
    ) {
      status = STOPPED;
    } else if (
      v.status === UNKNOWN ||
      v.status === OFFLINE ||
      (v.status === OFFLINE && v.lastUpdateInMinutes > LAST_UPDATE_THRESHOLD) ||
      (v.status === ONLINE &&
        v.position &&
        v.position.attributes &&
        v.position.attributes.motion &&
        v.lastUpdateInMinutes > LAST_UPDATE_THRESHOLD) ||
      (v.status === OFFLINE &&
        v.position &&
        v.position.attributes.motion &&
        v.position.valid &&
        v.position.speed < SPEED_THRESHOLD)
    ) {
      status = OFFLINE;
    }
  }

  return status;
};

export const getReportLandmarkArgs = (positions) => {
  return positions.map((pos) => {
    return {
      lon: pos.longitude,
      lat: pos.latitude,
      positionId: pos.positionId
    };
  });
};
