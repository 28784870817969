import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";

import WebNotification from "./WebNotification";
import { closeEvent } from "../../actions/socketActions";

class Notification extends Component {
  displayed = [];
  shouldComponentUpdate({ events: notifications = [] }) {
    if (!notifications.length) {
      this.displayed = [];
      return false;
    }

    const { events: currentNotification } = this.props;

    if (this.displayed.includes(currentNotification.id)) {
      return false;
    } else {
      return true;
    }
  }

  componentDidUpdate() {
    const { events = [] } = this.props;
    events
      .filter(e => !e.closed && !this.displayed.includes(e.id))
      .map(event => {
        this.props.enqueueSnackbar("", {
          children: key => <WebNotification id={key} event={event} />,
          onClose: (e, reason, key) => {
            this.props.closeEvent(event.id);
          }
        });
        this.displayed = [...this.displayed, event.id];
        return null;
      });
  }

  render() {
    return null;
  }
}
Notification.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  events: state.socket.events
});

export default withSnackbar(
  connect(
    mapStateToProps,
    { closeEvent }
  )(Notification)
);
