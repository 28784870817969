import {
  GET_GEOFENCES,
  SAVE_GEOFENCE,
  DELETE_GEOFENCE,
  UPDATE_GEOFENCE,
  GET_USER_GEOFENCES,
  GET_USER,
} from "../actions/type";

const initialState = {
  geofences: [],
  parsedGeofences: [],
  userGeofences: [],
};

const POL = "POLYGON";
const CIR = "CIRCLE";

const parseGeofences = (geofences) => {
  const len = geofences.length;
  let gfncs = [];
  if (len > 0) {
    gfncs = geofences.map((geofence) => {
      return generateCoordinates(geofence);
    });
  }

  return gfncs;
};

const generateCoordinates = ({ id, area, name }) => {
  const type = area.includes(POL) ? POL : CIR;
  let strArea = area.replace(type, "");
  let coordinates = [];
  let radius;
  if (type === POL) {
    strArea = strArea.replace("((", "");
    strArea = strArea.replace("))", "");

    let arrArea = strArea.split(",");
    const latLon = arrArea.map((arr) => {
      const ll = arr.trimStart().split(" ");
      return [parseFloat(ll[1]), parseFloat(ll[0])];
    });

    coordinates = latLon;
  }
  // else {
  //   strArea = strArea.replace("(", "");
  //   strArea = strArea.replace(")", "");
  //   let circleProps = strArea.split(",");
  //   const latLon = circleProps[0].trimStart().split(" ");
  //   radius = parseFloat(circleProps[1]);
  //   coordinates = [parseFloat(latLon[1]), parseFloat(latLon[0])];
  // }

  return { id, name, type, coordinates, radius };
};

const deleteGeofence = (geofences, id) => {
  return geofences.filter((geo) => geo.id !== id);
};

const updateGeofence = (geofences, payload, isParsed) => {
  return geofences.map((geo) => {
    let g = { ...geo };
    if (g.id === payload.id) {
      if (isParsed) {
        g = { ...generateCoordinates(payload) };
      } else {
        g = { ...payload };
      }
    }

    return g;
  });
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GEOFENCES:
      return {
        ...state,
        geofences: action.payload.filter((geo) => geo.area.includes(POL)),
        parsedGeofences: parseGeofences(
          action.payload.filter((geo) => geo.area.includes(POL))
        ),
      };
    case SAVE_GEOFENCE:
      return {
        ...state,
        geofences: [...state.geofences, action.payload],
        parsedGeofences: parseGeofences([...state.geofences, action.payload]),
      };
    case UPDATE_GEOFENCE:
      return {
        ...state,
        geofences: updateGeofence(state.geofences, action.payload, false),
        parsedGeofences: updateGeofence(
          state.parsedGeofences,
          action.payload,
          true
        ),
      };
    case DELETE_GEOFENCE:
      const newGeofences = deleteGeofence(state.geofences, action.payload);
      return {
        ...state,
        geofences: newGeofences,
        parsedGeofences: parseGeofences(newGeofences),
      };
    case GET_USER_GEOFENCES:
      return {
        ...state,
        userGeofences: action.payload,
      };
    default:
      return state;
  }
}
