import {
  UPDATE_DEVICES,
  UPDATE_POSITIONS,
  UPDATE_EVENTS,
  CLOSE_EVENT
} from "./type";

export const updateDevices = devices => ({
  type: UPDATE_DEVICES,
  devices
});

export const updatePositions = positions => ({
  type: UPDATE_POSITIONS,
  positions
});

export const updateEvents = events => ({
  type: UPDATE_EVENTS,
  events: updateEvent(events)
});

const updateEvent = events => {
  return events.map(event => {
    event.closed = false;
    return event;
  });
};

export const closeEvent = id => dispatch => {
  dispatch({
    type: CLOSE_EVENT,
    payload: id
  });
};
