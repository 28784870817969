import { GET_SERVER } from "./type";
import { API_GPX } from "../utils/Constants";
import axios from "axios";

export const setServer = args => dispatch => {
  dispatch({
    type: GET_SERVER,
    payload: args
  });
};

export const getServer = () => dispatch => {
  axios.get(`${API_GPX}/server`).then(res => {
    if (res.status === 200) {
      dispatch({
        type: GET_SERVER,
        payload: res.data
      });
    }
  });
};
