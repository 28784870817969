import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DateMomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  w100: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  inputClass: {
    padding: ".5rem"
  },
  inputClassLabel: {
    transform: "translate(14px, 10px) scale(1)"
  }
}));

const FilterRoutes = ({ date, handleOnChangeDate, isLiveTrack }) => {
  const classes = useStyles();
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateMomentUtils}>
        <DatePicker
          margin="normal"
          label="Select Date ..."
          variant="outlined"
          className={classes.w100}
          InputLabelProps={{
            classes: {
              root: classes.inputClassLabel
            }
          }}
          InputProps={{
            classes: { input: classes.inputClass }
          }}
          disabled={isLiveTrack}
          format="MMMM DD, YYYY (dddd)"
          value={date}
          onChange={handleOnChangeDate}
          autoOk={true}
          TextFieldComponent={TextField}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

const mapStateToProps = state => ({
  isLiveTrack: state.map.isLiveTrack
});

export default connect(mapStateToProps)(FilterRoutes);
