import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SET_CONTENT_DIALOG,
  SET_STREETVIEW_CLOSE,
  SET_STREETVIEW_OPEN,
  SET_LOADER_CLOSE,
  SET_LOADER_OPEN,
} from "./type";

export const openDialog = (content, prevContent, data) => (dispatch) => {
  dispatch({
    type: OPEN_DIALOG,
    payload: { content, prevContent, data },
  });
};

export const closeDialog = () => (dispatch) => {
  dispatch({
    type: CLOSE_DIALOG,
  });
};

export const setContentDialog = (content, data) => (dispatch) => {
  dispatch({
    type: SET_CONTENT_DIALOG,
    payload: {
      content,
      data,
    },
  });
};

export const openLoader = () => (dispatch) => {
  dispatch({
    type: SET_LOADER_OPEN,
    payload: null,
  });
};

export const closeLoader = () => (dispatch) => {
  dispatch({
    type: SET_LOADER_CLOSE,
    payload: null,
  });
};

export const openStreetView = () => (dispatch) => {
  dispatch({
    type: SET_STREETVIEW_OPEN,
    payload: {
      open: true,
      lat: null,
      lon: null,
    },
  });
};

export const closeStreetView = () => (dispatch) => {
  dispatch({
    type: SET_STREETVIEW_CLOSE,
    payload: null,
  });
};
