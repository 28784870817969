import axios from "axios";
import { API_GPX } from "../utils/Constants";

export const saveGroupGeofencePermissions = () => {};

export const saveUserGeofencePermissions = (userId, geofenceId) => {
  axios
    .post(`${API_GPX}/permissions`, { userId, geofenceId })
    .then((res) => {
      // do something if necessary
    })
    .catch((err) => console.error(err));
};

export const deleteUserGeofencePermissions = (userId, geofenceId) => {
  axios
    .delete(`${API_GPX}/permissions`, { data: { userId, geofenceId } })
    .then((res) => {
      // do something if necessary
    })
    .catch((err) => console.error(err));
};
