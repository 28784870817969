import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import TopBar from "./TopBar";
import Dialog from "./DialogUtil";
import Loader from "./DialogUtilLoader";

import { openCloseDrawer } from "../../actions/settingsAction";
import SocketController from "../../SocketController";

import { getGroups } from "../../actions/groupsAction";
import { getGeofences } from "../../actions/geofenceAction";
import { getVehicles } from "../../actions/vehiclesActions";
import { getUsers } from "../../actions/userActions";

import { USER_ROLES } from "../../utils/Constants";

import StreetView from "../MapBox/GoogleStreetView";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    flexGrow: 1,
  },
}));

const AppWrapper = ({
  open,
  openCloseDrawer,
  isReadOnly,
  isAuth,
  getGeofences,
  getGroups,
  getVehicles,
  children,
  getUsers,
  userRole,
  isOpenStreetView,
}) => {
  const classes = useStyles();
  function handleDrawerOpen() {
    openCloseDrawer(!open);
  }

  const handleGetGeofence = (groupId) => {
    getGeofences(isReadOnly, groupId);
  };

  useEffect(() => {
    if (isAuth) {
      getGroups(handleGetGeofence);
      getVehicles();
      if (userRole === USER_ROLES.admin || userRole === USER_ROLES.manager) {
        getUsers();
      }
    }
  }, []);

  return (
    <div className={classes.root}>
      <SocketController isOpen={isAuth} />
      <CssBaseline />
      <TopBar onClickHideDrawer={handleDrawerOpen} />
      <Dialog />
      <Loader />
      {/* <MapillaryStreetView /> */}
      <StreetView />
      {children}
    </div>
  );
};

const mapStateToProps = (state) => ({
  open: state.settings.openDrawer,
  isReadOnly: state.user.user.readonly,
  isAuth: state.user.isAuth,
  userRole: state.user.role,
  isOpenStreetView: state.dialog.isOpenStreetView,
});

const mapDispatchToProps = {
  openCloseDrawer,
  getGeofences,
  getGroups,
  getVehicles,
  getUsers,
};

export default React.memo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AppWrapper))
);
