import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import { getServer } from "./actions/serverActions";

import PrivateRoute from "./utils/PrivateRoute";

// Layout
import AppWrapper from "./components/Layouts/AppWrapper";

// v2
import Login from "./components/Login";
import NotFound from "./components/Layouts/NotFound";
import Fleet from "./components/vehiclesv2/Fleet";
import Trips3 from "./components/vehiclesv2/Trips3";
import Report from "./components/reportsv2/Report";
import Geofence from "./components/MapBox/Geofence";
// import Geofence from "./components/Geofence/GeofenceList";

import Notification from "./components/WebNotifications/Notification";

import "./App.css";

class App extends Component {
  componentDidMount() {
    this.props.getServer();
  }
  render() {
    const { isAuth } = this.props;
    return (
      <div className="App">
        {isAuth ? <Notification /> : null}

        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (isAuth ? <Redirect to="/fleet" /> : <Login />)}
            />
            <AppWrapper>
              <PrivateRoute exact path="/fleet" component={Fleet} />
              <PrivateRoute exact path="/geofence/:id" component={Geofence} />
              <PrivateRoute exact path="/trips/:id" component={Trips3} />
              <PrivateRoute exact path="/livetrack/:id" component={Trips3} />
              <PrivateRoute exact path="/reports" component={Report} />
            </AppWrapper>
            <Route component={NotFound} />
          </Switch>
        </Router>
      </div>
    );
  }
}

App.propTypes = {
  getServer: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuth: state.user.isAuth,
});

export default connect(mapStateToProps, { getServer })(App);
