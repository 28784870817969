import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import classnames from "classnames";

import { getSession } from "../../actions/userActions";

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: "100%",
    marginRight: -drawerWidth,
    [theme.breakpoints.down("sm")]: {
      marginRight: "-100%",
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: "100%",
    marginRight: 0,
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  contentMap: {
    height: "100%",
    width: "100%",
    overflowY: "auto",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    marginTop: -theme.spacing(3),
  },
}));

const Main = ({ open, children }) => {
  const classes = useStyles();
  return (
    <main
      className={classnames(classes.content, {
        [classes.contentShift]: open,
      })}
    >
      <div className={classes.toolbar} />
      <Box className={classes.contentMap}>{children}</Box>
    </main>
  );
};

const mapStateToProps = (state) => ({
  open: state.settings.openDrawer,
});

export default connect(mapStateToProps, { getSession })(withRouter(Main));
