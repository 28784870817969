import React from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";

import { connect } from "react-redux";

const columns = [
  {
    name: "date",
    label: "Date"
  },
  {
    name: "time",
    label: "Time"
  },
  {
    name: "deviceName",
    label: "Device"
  },
  {
    name: "speed",
    label: "Speed"
  },
  {
    name: "address",
    label: "Address"
  },
  {
    name: "latitude",
    label: "Latitude"
  },
  {
    name: "longitude",
    label: "Longitude"
  }
];

const updateData = (data, vehicles) => {
  const newData = data.map(d => {
    const vIndex = vehicles.findIndex(v => v.id === d.deviceId);
    const v = vehicles[vIndex];

    d.deviceName = v.name;
    if (d.attributes.speed) {
      d.speed = d.attributes.speed > 0 ? Math.round(d.attributes.speed * 1.852) : 0;
    }

    return d;
  });

  return newData.filter(os => os.speed > 80 && os.speed < 100);
};

const Overspeed80 = props => {
  return (
    <MUIDataTable
      title={"Report - Overspeed 80"}
      data={updateData(props.overspeed, props.vehicles)}
      columns={columns}
      options={{
        print: false,
        selectableRows: false,
        downloadOptions: {
          filename: "Overspeed_80_" + moment().format("YYYYMMDDHHmmss") + ".csv"
        }
      }}
    />
  );
};

const mapStateToProps = state => ({
  overspeed: state.reports.overspeed,
  vehicles: state.vehicles.vehicles
});

export default connect(mapStateToProps)(Overspeed80);
