import React, { Component } from "react";

import Main from "../Layouts/Main";
import VehicleListDrawer from "./VehicleListDrawer";

// import { uploadGeos } from "../../actions/groupsAction";

import MapBox from "./MapBox";

class Fleet extends Component {
  render() {
    return (
      <React.Fragment>
        <Main>
          <MapBox />
        </Main>
        <VehicleListDrawer />
      </React.Fragment>
    );
  }
}

export default Fleet;
