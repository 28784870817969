import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { ListItem } from "@material-ui/core";
import classnames from "classnames";

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    marginTop: "3px"
  },
  chip: {
    margin: "4px"
  },
  onlineStatus: {
    backgroundColor: "#008000",
    color: "#fff3e0"
  },
  offlineStatus: {
    backgroundColor: "#ff1500",
    color: "#fff3e0"
  },
  unknownStatus: {
    backgroundColor: "#ff9800",
    color: "#fff3e0"
  }
}));
const FleetChips = ({ counts, filter }) => {
  const classes = useStyles();
  return (
    <ListItem>
      <div>
        <Chip
          label={`Total - ${counts.total}`}
          clickable
          className={classes.chip}
          color="primary"
          onClick={() => {
            filter({ isFilter: false, by: "", keyword: "" });
          }}
        />
        <Chip
          label={`Moving - ${counts.online}`}
          clickable
          className={classnames([classes.chip, classes.onlineStatus])}
          onClick={() => {
            filter({
              isFilter: true,
              by: "status",
              keyword: "moving",
              isMoving: true
            });
          }}
        />
        <Chip
          label={`Stopped - ${counts.offline}`}
          clickable
          className={classnames([classes.chip, classes.offlineStatus])}
          onClick={() => {
            filter({
              isFilter: true,
              by: "status",
              keyword: "stopped",
              isMoving: false
            });
          }}
        />
        <Chip
          label={`Offline - ${counts.unknown}`}
          clickable
          className={classnames([classes.chip, classes.unknownStatus])}
          onClick={() => {
            filter({
              isFilter: true,
              by: "status",
              keyword: "offline",
              isMoving: false
            });
          }}
        />
      </div>
    </ListItem>
  );
};

const mapStateToProps = state => ({
  counts: state.vehicles.vehicleCounts
});

export default connect(
  mapStateToProps,
  null
)(FleetChips);
