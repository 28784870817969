import { GET_SERVER, RESET_SERVER } from "../actions/type";

const initialState = {
  server: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SERVER:
      return {
        ...state,
        server: action.payload
      };
    case RESET_SERVER:
      return {
        server: {}
      };
    default:
      return state;
  }
}
