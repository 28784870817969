/* eslint no-restricted-globals: 0 */
import {
  GET_COMPLETE_LOGS,
  GENERATE_COMPLETE_LOGS,
  RESPONSE_COMPLETE_LOGS,
  GENERATE_GEOFENCE_P2P,
  RESPONSE_GENERATE_GEOFENCE_P2P,
  GET_GEOFENCE_ALERT,
  GENERATE_STOP_LOGS,
  RESPONSE_GENERATE_STOP_LOGS,
  GET_STOP_LOGS,
} from "../type";
import Worker from "../../workers/reports.worker.js";
const worker = new Worker();

export const generateCompleteLogs = (
  positions,
  stops,
  vehicles,
  dispatch,
  callback
) => {
  worker.postMessage({
    type: GENERATE_COMPLETE_LOGS,
    payload: { logs: positions, stops, vehicles },
  }); // eslint-disable-line no-restricted-globals

  worker.onmessage = (event) => {
    const { type, response } = event.data;
    if (type === RESPONSE_COMPLETE_LOGS) {
      dispatch({ type: GET_COMPLETE_LOGS, payload: response });
      callback();
    }
  };
};

export const generateStopLogs = (stops, dispatch, callback) => {
  worker.postMessage({
    type: GENERATE_STOP_LOGS,
    payload: {
      stops,
    },
  }); // eslint-disable-line no-restricted-globals

  worker.onmessage = (event) => {
    const { type, response } = event.data;

    if (type === RESPONSE_GENERATE_STOP_LOGS) {
      dispatch({ type: GET_STOP_LOGS, payload: response });
      callback();
    }
  };
};

export const generateGeofenceP2P = (
  vehicles,
  alerts,
  positions,
  geofences,
  dispatch,
  callback
) => {
  worker.postMessage({
    type: GENERATE_GEOFENCE_P2P,
    payload: {
      alerts,
      positions,
      vehicles,
      geofences,
    },
  });

  worker.onmessage = (event) => {
    const { type, response } = event.data;
    if (type === RESPONSE_GENERATE_GEOFENCE_P2P) {
      dispatch({ type: GET_GEOFENCE_ALERT, payload: response });
      callback();
    }
  };
};
