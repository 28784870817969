import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { DIALOG_CONTENT } from "../../utils/Constants";

import Geofence from "../Geofence/GeofenceList";
import User from "../User/UserList";

import { closeDialog, openDialog } from "../../actions/dialogAction";

function DialogUtil({ dialog, closeDialog, openDialog, isLoader }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getContent = () => {
    switch (dialog.content) {
      case DIALOG_CONTENT.geofence:
        return <Geofence userGeofence={false} />;
      case DIALOG_CONTENT.userGeofence:
        return <Geofence userGeofence={true} />;
      case DIALOG_CONTENT.user:
        return <User />;
      default:
        return null;
    }
  };

  const getTitle = () => {
    switch (dialog.content) {
      case DIALOG_CONTENT.userGeofence:
      case DIALOG_CONTENT.geofence:
        return "Geofence";
      case DIALOG_CONTENT.user:
        return "User";
      default:
        return "";
    }
  };

  const getActionButtons = () => {
    return (
      <React.Fragment>
        {dialog.prevContent ? (
          <Button
            autoFocus
            onClick={() => openDialog(dialog.prevContent, null)}
            color="primary"
          >
            Back
          </Button>
        ) : null}
        <Button autoFocus onClick={() => closeDialog()} color="primary">
          Close
        </Button>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        open={dialog.open}
        onClose={() => closeDialog()}
        aria-labelledby="responsive-dialog-title"
        disableBackdropClick={true}
      >
        <React.Fragment>
          <DialogTitle id="responsive-dialog-title">{getTitle()}</DialogTitle>
          <DialogContent>{getContent()}</DialogContent>
          <DialogActions>{getActionButtons()}</DialogActions>
        </React.Fragment>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  dialog: state.dialog,
});

export default withRouter(
  connect(mapStateToProps, { closeDialog, openDialog })(DialogUtil)
);
