import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";

import { DIALOG_CONTENT } from "../../utils/Constants";

import { logout } from "../../actions/userActions";

import { setMapView } from "../../actions/settingsAction";
import { setLiveTrack } from "../../actions/mapActions";
import {
  deactivateLiveTracking,
  activateLiveTracking,
} from "../../actions/vehiclesActions";
import { openDialog } from "../../actions/dialogAction";

import { USER_ROLES } from "../../utils/Constants";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
}));

function TopBar({
  history,
  logout,
  setMapView,
  setLiveTrack,
  deactivateLiveTracking,
  groupIds,
  openDialog,
  userRole,
  activateLiveTracking,
  vehicle,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGotoFleet = () => {
    localStorage.setItem("currentUrl", "/fleet");
    localStorage.setItem("currentView", 1);
    setLiveTrack(false);
    setMapView(1);
    history.push("/fleet");
    deactivateLiveTracking();
    handleClose();
  };

  const handleGoToReport = () => {
    localStorage.setItem("currentUrl", "/reports");
    localStorage.setItem("currentView", 1);
    setLiveTrack(false);
    setMapView(1);
    history.push("/reports");
    deactivateLiveTracking();
    handleClose();
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const { location } = history;
    const currentUrl = localStorage.getItem("currentUrl");
    const currentView = localStorage.getItem("currentView");
    if (
      currentUrl &&
      currentUrl.includes("fleet") &&
      currentUrl !== location.pathname
    ) {
      //handleGotoFleet();
    } else if (
      currentUrl &&
      currentUrl.includes("trips") &&
      location.pathname === "/fleet"
    ) {
      if (vehicle) {
        setMapView(parseInt(currentView));
        history.push(currentUrl);
      }
    } else if (
      currentUrl &&
      currentUrl.includes("livetrack") &&
      location.pathname === "/fleet"
    ) {
      if (vehicle) {
        setLiveTrack(true);
        activateLiveTracking();
        setMapView(parseInt(currentView));
        history.push(currentUrl);
      }
    }
  });

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" noWrap className={classes.title}>
          GPX Tracker
        </Typography>
        <Button color="inherit" onClick={handleClick}>
          <MenuIcon />
        </Button>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleGotoFleet}>Fleet</MenuItem>
          {groupIds.length > 0 ? (
            <MenuItem
              onClick={() => {
                openDialog(DIALOG_CONTENT.geofence, null, null);
                handleClose();
              }}
            >
              Geofence
            </MenuItem>
          ) : null}
          {userRole === USER_ROLES.admin || userRole === USER_ROLES.manager ? (
            <MenuItem
              onClick={() => {
                openDialog(DIALOG_CONTENT.user, null, null);
                handleClose();
              }}
            >
              User
            </MenuItem>
          ) : null}
          <MenuItem onClick={handleGoToReport}>Reports</MenuItem>
        </Menu>
        <Button color="inherit" onClick={handleLogout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = (state) => ({
  groupIds: state.groups.groupIds,
  userRole: state.user.role,
  vehicle: state.vehicles.vehicle,
});

export default withRouter(
  connect(mapStateToProps, {
    setMapView,
    logout,
    setLiveTrack,
    deactivateLiveTracking,
    openDialog,
    activateLiveTracking,
  })(TopBar)
);
