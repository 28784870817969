import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSession, checkSession } from "../actions/userActions";
import { API_GPX } from "../utils/Constants";
import axios from "axios";

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: "400px",
      margin: "0 auto",
    },
    textAlign: "center",
  },
  rootErrorPanel: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  w100: {
    width: "100% !important",
  },
  margin: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "100px",
    },
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  inputProps: {
    padding: "12px 8px",
  },
});

axios.defaults.withCredentials = true;

class Login extends Component {
  state = {
    email: "",
    password: "",
    isLogin: false,
    isErrorLogin: false,
    validAuth: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.validAuth !== prevState.validAuth) {
      if (this.state.validAuth) {
        this.props.getSession();
      }
    }
  }

  handleOnClickLogin = () => {
    this.setState({ isErrorLogin: false });
    const { email, password } = this.state;
    fetch(`${API_GPX}/session`, {
      method: "POST",
      body: new URLSearchParams(`email=${email}&password=${password}`),
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          this.props.getSession();
        } else {
          this.setState({ isErrorLogin: true });
        }
      })
      .catch((err) => console.log(err));
  };

  handleOnChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleUpdateValidAuth = (valid) => {
    this.setState({ validAuth: valid });
  };

  componentDidMount() {
    checkSession(this.handleUpdateValidAuth);
    const btn = this.refs.btnLogin;
    btn.focus();
  }

  render() {
    const { classes } = this.props;
    const { isErrorLogin } = this.state;
    const errorPanel = () => {
      return (
        <Paper className={classes.rootErrorPanel}>
          <Typography variant="h6" component="h6">
            Login Error! Please try again.
          </Typography>
        </Paper>
      );
    };
    return (
      <div className={classes.margin}>
        <Paper square={true} className={classes.root} elevation={1}>
          <Typography variant="h3" component="h3">
            GPXTracker
          </Typography>
          <hr />
          {isErrorLogin ? errorPanel() : null}
          <form>
            <TextField
              id="email"
              label="Email"
              autoComplete="email"
              className={classes.w100}
              onChange={this.handleOnChange}
              InputLabelProps={{ shrink: true }}
              inputProps={{ className: classes.inputProps }}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="password"
              label="Password"
              autoComplete="password"
              type="password"
              className={classes.w100}
              onChange={this.handleOnChange}
              inputProps={{ className: classes.inputProps }}
              InputLabelProps={{ shrink: true }}
              margin="normal"
              variant="outlined"
            />
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="Add"
              className={classes.w100}
              ref="btnLogin"
              onClick={this.handleOnClickLogin}
            >
              LOGIN
            </Fab>
          </form>
        </Paper>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  getSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuth: state.user.isAuth,
  isLogout: state.user.isLogout,
});

export default connect(mapStateToProps, { getSession })(
  withStyles(styles)(withRouter(Login))
);
