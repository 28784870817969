import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SET_CONTENT_DIALOG,
  SET_LOADER_CLOSE,
  SET_LOADER_OPEN,
  SET_STREETVIEW_OPEN,
  SET_STREETVIEW_CLOSE,
} from "../actions/type";

const initialState = {
  open: false,
  content: null,
  data: null,
  prevContent: null,
  isLoaderOpen: false,
  streetView: {
    open: false,
    lat: null,
    lon: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        open: true,
        content: action.payload.content,
        prevContent: action.payload.prevContent,
        data: action.payload.data,
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        open: false,
        content: null,
        prevContent: null,
        data: null,
      };
    case SET_LOADER_OPEN:
      return {
        ...state,
        isLoaderOpen: true,
      };
    case SET_LOADER_CLOSE:
      return {
        ...state,
        isLoaderOpen: false,
      };
    case SET_CONTENT_DIALOG:
      return {
        ...state,
        content: action.payload.content,
        data: action.payload.data,
      };
    case SET_STREETVIEW_OPEN:
      return {
        ...state,
        streetView: {
          open: true,
          lat: action.payload.lat,
          lon: action.payload.lon,
        },
      };
    case SET_STREETVIEW_CLOSE:
      return {
        ...state,
        streetView: {
          open: false,
          lat: null,
          lon: null,
        },
      };
    default:
      return state;
  }
}
