import {
  GET_GEOFENCES,
  SAVE_GEOFENCE,
  UPDATE_GEOFENCE,
  DELETE_GEOFENCE,
  GET_USER_GEOFENCES,
} from "./type";
import axios from "axios";
import { API_GPX } from "../utils/Constants";

export const getGeofences = (readOnly, groupId) => (dispatch) => {
  let url = `${API_GPX}/geofences`;
  axios
    .get(url)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: GET_GEOFENCES,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.error(err));
};

export const saveGeofence = (geofence, groupId, callback) => (dispatch) => {
  console.log(groupId);
  axios
    .post(`${API_GPX}/geofences`, geofence)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: SAVE_GEOFENCE,
          payload: res.data,
        });
        axios.post(`${API_GPX}/permissions`, {
          groupId,
          geofenceId: res.data.id,
        });
        callback(true, true);
      }
    })
    .catch((err) => console.error(err));
};

export const updateGeofence = (geofence, callback) => (dispatch) => {
  axios
    .put(`${API_GPX}/geofences/${geofence.id}`, geofence)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: UPDATE_GEOFENCE,
          payload: res.data,
        });

        callback(true, true);
      }
    })
    .catch((err) => console.error(err));
};

export const deleteGeofence = (id, callback) => (dispatch) => {
  axios.delete(`${API_GPX}/geofences/${id}`).then((res) => {
    dispatch({
      type: DELETE_GEOFENCE,
      payload: id,
    });

    callback(true, true);
  });
};

export const getUserGeofences = (id, callback) => (dispatch) => {
  axios
    .get(`${API_GPX}/geofences?userId=${id}`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: GET_USER_GEOFENCES,
          payload: res.data,
        });

        callback();
      }
    })
    .catch((err) => console.error(err));
};
