import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Geofence from "./Geofence";
import Spinner from "../Layouts/Spinner";

import { closeDialog, closeLoader } from "../../actions/dialogAction";

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={`${classes[variant]} ${className}`}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={`${classes.icon} ${classes.iconVariant}`} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  inputClassLabel: {
    transform: "translate(14px, 10px) scale(1)",
  },
  inputClass: {
    padding: ".5rem",
  },
  w100: {
    width: "100%",
    marginTop: "0",
  },
  filterSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    height: "35px",
  },
  space: {
    width: "5px",
  },
}));

function GeofenceList({
  geofence,
  history,
  closeDialog,
  userGeofence,
  closeLoader,
}) {
  const classes = useStyles();
  const [geofences, setGeofences] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [openAlert, setOpenAlert] = useState({
    open: false,
    content: "",
    variant: "info",
  });
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (!keyword) {
      setGeofences(geofence);
    } else {
      setGeofences(
        geofence.filter((geo) =>
          geo.name.toLowerCase().includes(keyword.toLowerCase())
        )
      );
    }
  }, [keyword, update]);

  const handleOnClickCreateGeofence = () => {
    closeDialog();
    history.push("/geofence/-1");
  };

  const handleDeleteGeofenceCallback = (isOpen, isSuccess) => {
    closeLoader();
    setOpenAlert({
      open: isOpen,
      content: isSuccess
        ? "Geofence was deleted successfully!"
        : "There is an error deleting Geofence!",
      variant: isSuccess ? "success" : "error",
    });

    setUpdate(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert({
      ...openAlert,
      open: false,
    });

    setUpdate(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openAlert.open}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <MySnackbarContentWrapper
          onClose={handleCloseAlert}
          variant={openAlert.variant}
          message={openAlert.content}
        />
      </Snackbar>
      <div className={classes.filterSection}>
        <TextField
          id="txtGeofence"
          label="Search Geofence ..."
          InputLabelProps={{
            classes: {
              root: classes.inputClassLabel,
            },
          }}
          InputProps={{
            classes: { input: classes.inputClass },
          }}
          className={classes.w100}
          margin="normal"
          variant="outlined"
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
        />
        <div className={classes.space}></div>
        {userGeofence ? null : (
          <Button
            variant="contained"
            size="small"
            color="primary"
            classes={{ root: classes.button }}
            onClick={handleOnClickCreateGeofence}
          >
            New
          </Button>
        )}
      </div>
      {geofences.length > 0 ? (
        <React.Fragment>
          <List component="nav" aria-label="main mailbox folders">
            {geofences.map((geo) => (
              <Geofence
                key={geo.id}
                geofence={geo}
                deleteCallback={handleDeleteGeofenceCallback}
                userGeofence={userGeofence}
              />
            ))}
          </List>
        </React.Fragment>
      ) : geofence.length < 1 ? (
        <h4>No available Geofence</h4>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { geofences, userGeofences } = state.geofences;
  let nGeofences = [...geofences];

  if (userGeofences) {
    const userGeofenceIds = userGeofences.map((geo) => geo.id);

    nGeofences.map((geo) => {
      if (userGeofenceIds.includes(geo.id)) {
        geo.isUserGeofence = true;
      } else {
        geo.isUserGeofence = false;
      }
      return geo;
    });
  }

  return {
    geofence: nGeofences,
  };
};

export default withRouter(
  connect(mapStateToProps, { closeDialog, closeLoader })(GeofenceList)
);
