import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import DrawerMain from "../Layouts/DrawerMain";
import Main from "../Layouts/Main";
import MapBox from "./MapBox";
import Route from "./TripHistory/vRoute";
import FilterRoutes from "./TripHistory/FilterRoutes";
import {
  createLandmarkContent,
  createPlaceContent,
} from "../../utils/MapUtils";

import { List, Typography, ListItem, ListItemText } from "@material-ui/core";
import { getNearestLandmark2 } from "../../actions/mapActions";

import {
  getVehicle,
  getTripHistory,
  cleanUpTripsAndLiveTrack,
} from "../../actions/vehiclesActions";
import { getUTCDate } from "../../utils/MapUtils";

class Trips extends Component {
  state = {
    date: moment().format("YYYY-MM-DD"),
    positions: [],
    position: {},
    vehicle: {},
    popupContent: {},
    open: true,
    isLiveTrack: false,
    mapView: 1,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.vehicle !== prevProps.vehicle) {
      this.setState({
        vehicle: this.props.vehicle,
      });
    }
    if (this.props.popupContent !== prevProps.popupContent) {
      this.setState({ popupContent: this.props.popupContent });
    }
    if (this.props.positions !== prevProps.positions) {
      this.setState({
        positions: this.props.positions,
        position: this.props.positions[0] ? this.props.positions[0] : {},
      });
      this.props.getNearestLandmark2(
        this.mergePositionWithVehicle(
          this.state.vehicle,
          this.props.positions[0] ? this.props.positions[0] : {}
        )
      );
    }
    if (this.props.open !== prevProps.open) {
      this.setState({ open: this.props.open });
    }
    if (this.props.isLiveTrack !== prevProps.isLiveTrack) {
      this.setState({ isLiveTrack: this.props.isLiveTrack });
    }
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    this.props.getVehicle(id);
    this.setState({ mapView: this.props.mapView }, () => {
      this.handleGetTrips();
    });
    localStorage.setItem("currentUrl", match.url);
  }

  componentWillUnmount() {
    this.setState({
      date: moment().format("YYYY-MM-DD"),
      positions: [],
      geofences: [],
      vehicle: {},
      open: true,
      isLiveTrack: false,
      mapView: 1,
    });
    this.props.cleanUpTripsAndLiveTrack();
  }

  mergePositionWithVehicle = (vehicle, position) => {
    vehicle.position = position;
    vehicle.isPopup = false;
    return vehicle;
  };

  handleOnChangeDate = (mom) => {
    this.setState({ date: mom.format("YYYY-MM-DD") }, () => {
      this.handleGetTrips();
    });
  };

  handleGetTrips = () => {
    const { date } = this.state;
    const { id } = this.props.match.params;
    const d = getUTCDate(date);
    const args = {
      deviceId: id,
      from: d.from,
      to: d.to,
    };
    this.props.getTripHistory(args);
  };

  handleCreateRouteList = () => {
    const { positions, vehicle } = this.state;

    return positions.length > 0 ? (
      positions.map((pos, i) => {
        return <Route route={pos} key={pos.id} vehicle={vehicle} />;
      })
    ) : (
      <ListItem>
        <ListItemText>No Data</ListItemText>
      </ListItem>
    );
  };

  render() {
    const { date, vehicle, mapView, popupContent } = this.state;
    return (
      <React.Fragment>
        <Main>
          <MapBox />
        </Main>
        <DrawerMain>
          <Typography
            variant="subtitle2"
            gutterBottom
            style={{ padding: "8px 8px 0 8px" }}
          >
            {mapView === 2 ? "Trip History" : "Live Track"}
          </Typography>
          <Typography variant="h6" gutterBottom style={{ padding: "0 8px" }}>
            {vehicle ? vehicle.name : null}
          </Typography>
          {popupContent && popupContent.landmark ? (
            <Typography
              variant="body2"
              gutterBottom
              style={{ padding: "0 8px" }}
            >
              {createLandmarkContent(popupContent.landmark)}
              <br />
              {createPlaceContent(popupContent.landmark.place)}
            </Typography>
          ) : null}
          <FilterRoutes
            date={date}
            handleOnChangeDate={this.handleOnChangeDate}
          />
          <List>{this.handleCreateRouteList()}</List>
        </DrawerMain>
      </React.Fragment>
    );
  }
}

Trips.propTypes = {
  getVehicle: PropTypes.func.isRequired,
  getTripHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  open: state.settings.openDrawer,
  positions: state.vehicles.vPositions,
  vehicle: state.vehicles.vehicle,
  isLiveTrack: state.map.isLiveTrack,
  mapView: state.settings.mapView,
  popupContent: state.map.popupContent,
});

const mapDispatchToProps = {
  getVehicle,
  getTripHistory,
  cleanUpTripsAndLiveTrack,
  getNearestLandmark2,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trips);
