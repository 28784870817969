import { GET_GROUPS, RESET_GROUPS } from "../actions/type";

const initialState = {
  groups: [],
  groupIds: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_GROUPS:
      return {
        ...state,
        groups: action.payload.groups.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;

          return 0;
        }),
        groupIds: action.payload.ids
      };
    case RESET_GROUPS:
      return {
        groups: []
      };
    default:
      return state;
  }
}
