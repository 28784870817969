import {
  GET_USER,
  LOGOUT_USER,
  SET_VIEWPORT,
  SET_IS_ADMIN,
  RESET_GROUPS,
  RESET_VEHICLES,
  RESET_SERVER,
  RESET_SETTINGS,
  RESET_REPORTS,
  RESET_MAP,
  SET_MAP_CENTER,
  GET_USERS,
} from "./type";
import { API_GPX } from "../utils/Constants";
import axios from "axios";

export const setUser = (args) => (dispatch) => {
  dispatch({ type: GET_USER, payload: args });
};

export const getSession = () => (dispatch) => {
  axios
    .get(`${API_GPX}/session`)
    .then((sess) => {
      if (sess.status === 200) {
        dispatch({ type: GET_USER, payload: sess.data });
        dispatch({
          type: SET_VIEWPORT,
          payload: {
            center: { lat: sess.data.latitude, lng: sess.data.longitude },
            zoom: sess.data.zoom,
          },
        });
        dispatch({
          type: SET_IS_ADMIN,
          payload: {
            isAdmin: sess.data.administrator,
            isMonitoring: parseInt(sess.data.attributes.monitoring)
              ? true
              : false,
          },
        });
        dispatch({
          type: SET_MAP_CENTER,
          payload: {
            center: [sess.data.longitude, sess.data.latitude],
            zoom: [sess.data.zoom],
          },
        });
      }
    })
    .catch((e) => {});
};

export const checkSession = (callback) => {
  axios
    .get(`${API_GPX}/session`, { validateStatus: false })
    .then((res) => {
      if (res.status === 200) {
        callback(true);
      }
    })
    .catch((e) => callback(false));
};

export const logout = () => (dispatch) => {
  axios
    .delete(`${API_GPX}/session`)
    .then((sess) => {
      if (sess.status === 204) {
        dispatch({ type: LOGOUT_USER, payload: sess.data });
        dispatch({ type: RESET_SERVER, payload: [] });
        dispatch({ type: RESET_GROUPS, payload: [] });
        dispatch({ type: RESET_VEHICLES, payload: [] });
        dispatch({ type: RESET_SETTINGS, payload: [] });
        dispatch({ type: RESET_REPORTS, payload: [] });
        dispatch({ type: RESET_MAP, payload: [] });
      }
      localStorage.removeItem("currentUrl");
      localStorage.removeItem("currentView");
      window.location.href = "/";
    })
    .catch((e) => {
      console.log(e);
    });
};

// CRUD Actions
export const getUsers = () => (dispatch) => {
  axios
    .get(`${API_GPX}/users`)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: GET_USERS,
          payload: res.data,
        });
      }
    })
    .catch();
};
