import React from "react";
import { connect } from "react-redux";
import { Layer, Feature } from "react-mapbox-gl";

import { getNearestLandmark2 } from "../../actions/mapActions";

import { getGpxStatus } from "../../utils/MapUtils";
import {
  OFFLINE,
  ONLINE,
  MOVING,
  STOPPED,
  UNKNOWN,
} from "../../utils/Constants";

const layout = {
  "icon-image": {
    type: "identity",
    property: "icon",
  },
  "icon-anchor": "bottom",
  "icon-allow-overlap": true,
  "text-field": {
    type: "identity",
    property: "name",
  },
  "text-offset": [0, 1.5],
  "text-anchor": "bottom",
  "text-size": 12,
  "text-allow-overlap": true,
};

const paint = {
  "text-color": "#000",
  "text-halo-color": "#fff",
  "text-halo-width": 0.75,
};

const getStatus = (vehicle) => {
  let status = getGpxStatus(vehicle);
  if (status === MOVING) {
    status = ONLINE;
  } else if (status === STOPPED) {
    status = OFFLINE;
  } else if (status === OFFLINE) {
    status = UNKNOWN;
  }

  return status;
};

const MapVehiclesLayer = ({ vehicles, mapView, getNearestLandmark2 }) => {
  return vehicles && mapView == 1 ? (
    <Layer layout={layout} paint={paint}>
      {vehicles.map((vehicle) => {
        const status = getStatus(vehicle);
        return (
          <Feature
            key={vehicle.id}
            coordinates={
              vehicle.position
                ? [vehicle.position.longitude, vehicle.position.latitude]
                : { latitude: 0, longitude: 0 }
            }
            properties={{
              name: vehicle.name,
              icon: `map_pin_${status}_${vehicle.category}`,
            }}
            onClick={() => {
              vehicle.isPopup = true;
              getNearestLandmark2(vehicle);
            }}
          />
        );
      })}
    </Layer>
  ) : null;
};

const mapStateToProps = (state) => ({
  vehicles: state.vehicles.filteredVehicles,
  mapView: state.settings.mapView,
});

const mapDispatchToProps = {
  getNearestLandmark2,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapVehiclesLayer);
