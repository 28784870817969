import { SET_POPUP, SET_MAP_CENTER, SET_LIVE_TRACK } from "./type";
import axios from "axios";
import { API_LANDMARK } from "../utils/Constants";

axios.defaults.withCredentials = true;

export const popupDispatchToState = () => (dispatch) => {
  dispatch({ type: SET_POPUP, payload: { isPopup: false, popupContent: {} } });
};

export const setLiveTrack = (isLiveTrack) => (dispatch) => {
  dispatch({ type: SET_LIVE_TRACK, payload: isLiveTrack });
};

export const getNearestLandmark2 = (args) => (dispatch) => {
  let params = new URLSearchParams();
  params.append("positionId", args.position.id);
  params.append("lat", args.position.latitude);
  params.append("lon", args.position.longitude);

  axios
    .post(API_LANDMARK, params)
    .then((landmark) => {
      if (landmark.status === 200) {
        args.landmark = landmark.data;
        // if (args.isPopup === false) {
        //   dispatch({
        //     type: SET_POPUP,
        //     payload: {
        //       isPopup: false,
        //       popupContent: args,
        //     },
        //   });
        // }
        dispatch({
          type: SET_POPUP,
          payload: {
            isPopup: args.isPopup,
            popupContent: args,
          },
        });
        dispatch({
          type: SET_MAP_CENTER,
          payload: {
            center: [args.position.longitude, args.position.latitude],
          },
        });
      }
    })
    .catch((err) => console.log(err));
};
