import {
  GET_VEHICLES,
  GET_POSITIONS,
  UPDATE_POSITION,
  UPDATE_VEHICLE,
  GET_TRIPS,
  GET_VEHICLE,
  ACTIVATE_LIVE_TRACKING,
  DEACTIVATE_LIVE_TRACKING,
  CLEAN_UP_TRIPS_AND_LIVE,
  GET_VEHICLES_STOPS,
} from "./type";
import { API_GPX, API_LANDMARK } from "../utils/Constants";
import moment from "moment";
import axios from "axios";

axios.defaults.withCredentials = true;

export const getVehicles = () => (dispatch) => {
  axios
    .get(`${API_GPX}/devices`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: GET_VEHICLES, payload: res.data });
      }
    })
    .catch((e) => console.log(e));
};

export const getVehiclePositions = () => (dispatch) => {
  axios
    .get(`${API_GPX}/positions`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_POSITIONS,
          payload: res.data,
        });
      }
    })
    .catch((e) => console.log(e));
};

export const getVehicle = (id) => (dispatch) => {
  axios
    .get(`${API_GPX}/devices?id=${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: GET_VEHICLE, payload: res.data });
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

const processTripLiveRoutes = (stops, trips) => {
  return new Promise((resolve) => {
    let stoppedRoutes = [];
    const nTrips = [...trips];
    // Issue: some active routes or valid routes are inside the stop logs.

    // stops.map((stop) => {
    //   const { startTime, endTime } = stop;
    //   const routes = nTrips.filter((trip) =>
    //     moment(trip.fixTime).isBetween(startTime, endTime)
    //   );
    //   stoppedRoutes = [...stoppedRoutes, ...routes];
    //   return stop;
    // });

    // for (let i = 0, len = stoppedRoutes.length; i < len; i++) {
    //   const rIdx = nTrips.findIndex((t) => t.id === stoppedRoutes[i].id);
    //   nTrips.splice(rIdx, 1);
    // }

    // stops.map((s) => {
    //   const { startTime, endTime } = s;
    //   const sIdx = nTrips.findIndex((n) => n.id === s.positionId);
    //   const sPos = nTrips[sIdx];
    //   if (sPos) {
    //     sPos.isStop = true;
    //     sPos.startTime = startTime;
    //     sPos.endTime = endTime;
    //     nTrips.splice(sIdx, 1, sPos);
    //     return s;
    //   }
    // });

    resolve(nTrips);
  });
};

export const getTripHistory = (args) => (dispatch) => {
  const qString = `deviceId=${args.deviceId}&from=${args.from}&to=${args.to}&page=1&start=0&limit=25`;
  const sqString = `deviceId=${args.deviceId}&from=${args.from}&to=${args.to}`;

  const reqTrips = axios.get(`${API_GPX}/reports/route?${qString}`);
  const reqStops = axios.get(`${API_GPX}/reports/stops?${sqString}`);

  axios
    .all([reqStops, reqTrips])
    .then(
      axios.spread((stops, trips) => {
        if (stops.data.length > 0) {
          const rRoutes = processTripLiveRoutes(stops.data, trips.data);
          rRoutes.then((res) => {
            dispatch({
              type: GET_TRIPS,
              payload: res,
            });
            dispatch({
              type: GET_VEHICLES_STOPS,
              payload: stops.data,
            });
          });
        } else {
          dispatch({
            type: GET_TRIPS,
            payload: trips.data,
          });
          dispatch({
            type: GET_VEHICLES_STOPS,
            payload: stops.data,
          });
        }
      })
    )
    .catch((err) => console.log(err));
};

export const getNearestLandmark = (args, callback) => {
  let params = new URLSearchParams();
  params.append("positionId", args.positionId);
  params.append("lat", args.lat);
  params.append("lon", args.lon);
  axios
    .post(API_LANDMARK, params)
    .then((landmark) => {
      callback(landmark.data);
    })
    .catch((err) => console.log(err));
};

export const updateVehiclePosition = (args) => (dispatch) => {
  dispatch({
    type: UPDATE_POSITION,
    payload: args,
  });
};

export const updateVehicle = (args) => (dispatch) => {
  dispatch({
    type: UPDATE_VEHICLE,
    payload: args,
  });
};

export const activateLiveTracking = () => (dispatch) => {
  dispatch({
    type: ACTIVATE_LIVE_TRACKING,
    payload: true,
  });
};

export const deactivateLiveTracking = () => (dispatch) => {
  dispatch({
    type: DEACTIVATE_LIVE_TRACKING,
    payload: false,
  });
};

export const cleanUpTripsAndLiveTrack = () => (dispatch) => {
  dispatch({
    type: CLEAN_UP_TRIPS_AND_LIVE,
    payload: null,
  });
};
