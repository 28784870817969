import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    maxWidth: 350,
    minWidth: 250,
    backgroundColor: "#3f51b5"
  }
}));

const upperCase = str => {
  return str.toUpperCase();
};

const generateEventText = str => {
  return str
    .replace(/([A-Z]+)/g, "$1")
    .replace(/([A-Z][a-z])/g, " $1")
    .replace(/^[a-z]/g, upperCase);
};

const getEventype = event => {
  if (event.type !== "alarm") {
    return generateEventText(event.type);
  } else {
    return generateEventText(event.attributes.alarm);
  }
};

const getVehicle = (event, vehicles) => {
  const vIndex = vehicles.findIndex(vehicle => vehicle.id === event.deviceId);
  return vehicles[vIndex];
};

const WebNotification = props => {
  const { event, vehicles } = props;
  const classes = useStyles();

  const displayNotification = () => {
    const vehicle = getVehicle(event, vehicles);

    return vehicle ? (
      <Paper className={classes.root}>
        <Typography variant="subtitle2" style={{ color: "white" }}>
          {moment(event.serverTime).format("YYYY-MM-DD HH:mm:ss")}
        </Typography>
        <Typography variant="subtitle1" style={{ color: "white" }}>
          {`${getEventype(event)} - ${getVehicle(event, vehicles).name}`}
        </Typography>
      </Paper>
    ) : null;
  };

  return event ? displayNotification() : null;
};

const mapStateToProps = state => ({
  vehicles: state.vehicles.vehicles
});

export default connect(mapStateToProps)(WebNotification);
