import React, { Component } from "react";
import { connect } from "react-redux";
import ReactMapboxGl, {
  Popup,
  Layer,
  Feature,
  ZoomControl,
} from "react-mapbox-gl";

import {
  OFFLINE,
  ONLINE,
  MOVING,
  STOPPED,
  UNKNOWN,
  MAP_ACCESS_TOKEN,
} from "../../utils/Constants";
import { getGpxStatus } from "../../utils/MapUtils";

import {
  popupDispatchToState,
  getNearestLandmark2,
} from "../../actions/mapActions";
import sprite from "../../utils/sprite/style.json";
import moment from "moment";

import MapVehiclesLayer from "../MapBox/MapVehiclesLayer";
import MapVehiclePopup from "../MapBox/MapVehiclePopup";

const Map = ReactMapboxGl({
  accessToken: MAP_ACCESS_TOKEN,
});

class MapBox extends Component {
  state = {
    center: [],
    zoom: [15],
    positions: [],
    vehicles: [],
    geofences: [],
    vehicle: {},
    mapView: 1,
    isPopup: false,
    isGeofencePopup: false,
    popupContent: {},
    popupContentGeofence: {},
    stops: [],
    isMarkerGeojsonLoaded: false,
  };

  componentWillUnmount() {
    this.setState({
      center: [],
      zoom: [15],
      positions: [],
      vehicles: [],
      geofence: [],
      vehicle: {},
      mapView: 1,
      isPopup: false,
      isGeofencePopup: false,
      popupContent: {},
      popupContentGeofence: {},
      stops: [],
      isMarkerGeojsonLoaded: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.stops !== prevProps.stops) {
      this.setState({ stops: this.props.stops });
    }
    if (
      this.props.isPopup !== prevProps.isPopup ||
      this.props.popupContent !== prevProps.popupContent
    ) {
      this.setState({
        isPopup: this.props.isPopup,
        popupContent: this.props.popupContent,
      });
    }
    if (this.props.center !== prevProps.center) {
      this.setState({ center: this.props.center, zoom: this.props.zoom });
    }
    if (this.props.geofences !== prevProps.geofences) {
      this.setState({ geofences: this.props.geofences });
    }
    if (this.state.mapView === 2 || this.state.mapView === 3) {
      if (this.props.vehicle !== prevProps.vehicle) {
        this.setState({ vehicle: this.props.vehicle });
      }
      if (this.props.vPositions.length !== prevProps.vPositions.length) {
        const position = this.props.vPositions[0]
          ? this.props.vPositions[0]
          : this.props.user;

        if (position) {
          this.setState({
            positions: this.props.vPositions,
            center: [position.longitude, position.latitude],
          });
        } else {
          this.setState({
            positions: this.props.vPositions,
          });
        }
      }
    } //else if (this.state.mapView === 1) {
    //   if (this.props.vehicles !== prevProps.vehicles) {
    //     this.setState({
    //       vehicles: this.props.vehicles,
    //       positions: this.props.positions
    //     });
    //   }
    // }
  }

  componentDidMount() {
    this.setState({ geofences: this.props.geofences });
    if (this.state.mapView !== this.props.mapView) {
      this.setState({ mapView: this.props.mapView });
    }
  }

  handleGetStop = (id) => {
    const { stops } = this.state;
    const vStops = stops.filter((stop) => stop.deviceId === id);

    return vStops.length > 0 ? vStops[0] : [];
  };

  handleCreateSubheaderStopped = (stop) => {
    const stopFrom = stop ? stop.startTime : null;
    const stopTo = stop ? stop.endTime : null;
    return (
      <React.Fragment>
        {"Stopped"}
        <br />
        {stop.deviceId
          ? `${moment(stopFrom).format("HH:mm A")} - ${moment(stopTo).format(
              "HH:mm A"
            )}`
          : "--:--:-- - --:--:--"}{" "}
        {`${moment(stopFrom).format("dddd MM/DD/YYYY")}`}
      </React.Fragment>
    );
  };

  handleCreateCoordinates = (positions) => {
    return {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: positions.map((pos) => {
          return [pos.longitude, pos.latitude];
        }),
      },
    };
  };

  handleMapOnClick = () => {
    const { popupDispatchToState } = this.props;
    this.setState({ isGeofencePopup: false });
    popupDispatchToState();
  };

  handleCreatePopupSubheader = (vehicle) => {
    const { mapView } = this.props;
    const stop = this.handleGetStop(vehicle.id);

    if (mapView == 1) {
      return vehicle.position && vehicle.position.attributes.motion ? (
        <React.Fragment>
          {`Moving ${Math.round(vehicle.position.speed * 1.852)} km/h`}
          <br />
          {`as of ${moment(vehicle.position.deviceTime).format(
            "hh:mm A dddd MM/DD/YYYY"
          )}`}
        </React.Fragment>
      ) : (
        this.handleCreateSubheaderStopped(stop)
      );
    } else {
      return vehicle.position
        ? `as of ${moment(vehicle.position.deviceTime).format(
            "hh:mm A dddd MM/DD/YYYY"
          )}`
        : null;
    }
  };

  handleCreatePopup = (popupContent, lonlat) => {
    const { landmark, position, name } = popupContent;

    const subheader = this.handleCreatePopupSubheader(popupContent);

    return (
      <Popup coordinates={lonlat} anchor="bottom">
        <div style={{ maxWidth: "300px" }}>
          <strong>{name}</strong>

          {!subheader ? null : (
            <React.Fragment>
              <br />
              <strong>{subheader}</strong>
            </React.Fragment>
          )}
          {position && position.valid === false && position.speed < 3 ? (
            <React.Fragment>
              <br />
              {`[No GPS Data, location may not be accurate]`}
            </React.Fragment>
          ) : null}
          {landmark ? (
            <React.Fragment>
              <br />
              {this.handleGetLandmarkContent(landmark)}
              <br />
              {this.handleCreatePlaceContent(landmark.place)}
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </Popup>
    );
  };

  handleGetLandmarkContent = (landmark) => {
    let str = "";

    str += landmark.distanceInMeter > 0 ? `${landmark.distanceInMeter}m ` : "";
    str += landmark.distanceInMeter > 0 ? "From" : "";
    str +=
      landmark.direction && landmark.distanceInMeter > 0
        ? `${landmark.direction} `
        : "";
    str += ` ${landmark.landmarkName}`;

    return str;
  };

  handleCreatePlaceContent = (place) => {
    let str = "(";

    str +=
      place.distanceInKilometer > 0 ? `${place.distanceInKilometer}km ` : "";
    str +=
      place.direction && place.distanceInKilometer > 0
        ? `${place.direction} `
        : "";
    str += place.distanceInKilometer > 0 ? "From" : "";
    str += ` ${place.place}`;
    str += ")";
    return str;
  };

  handleGetStop = (id) => {
    const { stops } = this.state;
    const vStops = stops.filter((stop) => stop.deviceId === id);

    return vStops.length > 0 ? vStops[0] : [];
  };

  handleGetLatLng = (id) => {
    const { positions } = this.state;
    const posIndex = positions
      ? positions.findIndex((pos) => pos.deviceId === id)
      : -1;
    const position =
      posIndex > -1 ? positions[posIndex] : { latitude: 0, longitude: 0 };
    return [position.longitude, position.latitude];
  };

  handleCreateMarkers = (vehicles) => {
    const { positions } = this.state;

    return vehicles ? (
      <Layer
        type="symbol"
        layout={{
          "icon-image": {
            type: "identity",
            property: "icon",
          },
          "icon-anchor": "bottom",
          "icon-allow-overlap": true,
          "text-field": {
            type: "identity",
            property: "name",
          },
          "text-offset": [0, 1.5],
          "text-anchor": "bottom",
          "text-size": 12,
          "text-allow-overlap": true,
        }}
        paint={{
          "text-color": "#000",
          "text-halo-color": "#fff",
          "text-halo-width": 0.75,
        }}
      >
        {vehicles.map((v) => {
          v = {
            ...v,
            position:
              positions[positions.findIndex((pos) => pos.deviceId === v.id)],
          };
          let status = getGpxStatus(v);
          if (status === MOVING) {
            status = ONLINE;
          } else if (status === STOPPED) {
            status = OFFLINE;
          } else if (status === OFFLINE) {
            status = UNKNOWN;
          }
          return (
            <Feature
              key={v.id}
              coordinates={this.handleGetLatLng(v.id)}
              properties={{
                name: v.name,
                icon: `map_pin_${status}_${v.category}`,
              }}
              onClick={() => {
                this.props.getNearestLandmark2(v);
              }}
            />
          );
        })}
      </Layer>
    ) : null;
  };

  handleCreateLineAndSymbols = () => {
    const { positions, vehicle } = this.state;
    const mergeWithVehicleData = (pos) => {
      vehicle.position = pos;
      vehicle.isPopup = true;
      return vehicle;
    };
    const mappedRoutes = positions.map((position) => [
      position.longitude,
      position.latitude,
    ]);
    return (
      <React.Fragment>
        <Layer
          type="line"
          paint={{
            "line-color": "#4790E5",
            "line-width": 3,
          }}
        >
          <Feature coordinates={mappedRoutes} />
        </Layer>
        <Layer
          type="symbol"
          layout={{
            "icon-image": {
              type: "identity",
              property: "icon",
            },
            "icon-anchor": "center",
            "icon-rotate": {
              type: "identity",
              property: "rotate",
            },
            "icon-allow-overlap": true,
          }}
          paint={{
            "text-color": "#fff",
            "text-halo-color": "#000",
            "text-halo-width": 1,
          }}
        >
          {positions.map((position, i) => {
            return (
              <Feature
                key={position.id}
                coordinates={[position.longitude, position.latitude]}
                properties={{
                  rotate: position.course,
                  icon: i === 0 ? "arrow_current_blinking" : "arrow",
                }}
                onClick={() => {
                  this.props.getNearestLandmark2(
                    mergeWithVehicleData(position)
                  );
                }}
              />
            );
          })}
        </Layer>
      </React.Fragment>
    );
  };

  handleGetCirclePaint = (radius) => ({
    "circle-radius": radius / 2,
    "circle-color": "#E54E52",
    "circle-stroke-color": "#ff030a",
    "circle-opacity": 0.7,
    "circle-pitch-scale": "viewport",
    "circle-pitch-alignment": "viewport",
  });

  handleGetPolygonPaint = () => ({
    "fill-antialias": true,
    "fill-color": "#E54E52",
    "fill-opacity": 0.7,
  });

  handleGetCenter = (arr) => {
    var x = arr.map((a) => a[0]);
    var y = arr.map((a) => a[1]);
    var minX = Math.min.apply(null, x);
    var maxX = Math.max.apply(null, x);
    var minY = Math.min.apply(null, y);
    var maxY = Math.max.apply(null, y);
    return [(minX + maxX) / 2, (minY + maxY) / 2];
  };

  handleGenerateGeofences = () => {
    const { geofences } = this.state;
    return geofences
      ? geofences
          .filter((geofence) => geofence.type === "POLYGON")
          .map(({ name, coordinates }, idx) => {
            return (
              <Layer
                key={idx}
                type={"fill"}
                paint={this.handleGetPolygonPaint()}
                onClick={(args) => {
                  this.handleGeofenceOnClick(args, name);
                }}
              >
                <Feature
                  coordinates={[coordinates]}
                  properties={{ name }}
                ></Feature>
              </Layer>
            );
          })
      : null;
  };

  handleGeofenceOnClick = (args, name) => {
    this.setState({
      popupContentGeofence: { coord: args.lngLat, name },
      isGeofencePopup: true,
    });
  };

  handleCreatePopupGeofence = () => {
    const { coord, name } = this.state.popupContentGeofence;
    return (
      <Popup coordinates={coord} anchor="bottom">
        <div>
          <strong>{name}</strong>
        </div>
      </Popup>
    );
  };

  render() {
    const {
      isPopup,
      isGeofencePopup,
      popupContent,
      center,
      zoom,
      geofences,
      vehicle,
      mapView,
    } = this.state;
    const setCenter = () => {
      let mCenter = center.length > 0 ? center : this.props.center;
      if (isPopup) {
        mCenter = [
          popupContent.position.longitude,
          popupContent.position.latitude,
        ];
      }
      return mCenter;
    };
    return setCenter().length > 1 ? (
      <Map
        ref="thMap"
        style={sprite}
        containerStyle={{
          height: "100vh",
          width: "100vw",
        }}
        zoom={zoom}
        center={setCenter()}
        onClick={this.handleMapOnClick}
      >
        <ZoomControl position="top-left" />
        {geofences.length > 0 && this.handleGenerateGeofences()}
        {isGeofencePopup ? this.handleCreatePopupGeofence() : null}
        <MapVehiclesLayer />
        {isPopup && (
          <MapVehiclePopup vehicle={popupContent} lonlat={setCenter()} />
        )}
        {(vehicle && mapView === 2) || (vehicle && mapView === 3)
          ? this.handleCreateLineAndSymbols()
          : null}
      </Map>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  mapView: state.settings.mapView,
  isPopup: state.map.isPopup,
  popupContent: state.map.popupContent,
  center: state.map.center,
  zoom: state.map.zoom,
  geofences: state.geofences.parsedGeofences,
  vehicle: state.vehicles.vehicle,
  vPositions: state.vehicles.vPositions,
});

export default React.memo(
  connect(mapStateToProps, { popupDispatchToState, getNearestLandmark2 })(
    MapBox
  )
);
