import axios from "axios";
import {
  GET_OVERSPEED,
  GET_OVERSPEED_POSITIONS,
  DISPATCH_VEHICLES_TO_REPORT,
  DISPATCH_SELECTED_VEHICLES_TO_REPORT,
  RESET_REPORT_DATA,
  GET_STOP_LOGS,
  GET_SUMMARY_REPORT,
  GET_ALERTS,
  GET_ALERTS_POSITIONS,
  GET_GEOFENCE_REPORT,
} from "./type";
import { getISOOffSet, getReportLandmarkArgs } from "../utils/MapUtils";
import { API_GPX, API_LANDMARK } from "../utils/Constants";
import { extractIdsWithKey } from "../utils/ReportUtils";
import {
  generateCompleteLogs,
  generateGeofenceP2P,
  generateStopLogs,
} from "./workers/reportsActionWorkers";
import moment from "moment";

axios.defaults.withCredentials = true;

const createParams = (args) => {
  const dates = getISOOffSet(args);

  let qString = `from=${dates.from}&to=${dates.to}`;
  for (let i = 0; i < args.devices.length; i++) {
    qString += `&deviceId=${args.devices[i]}`;
  }

  return qString;
};

const getReportRoutes = (args) => {
  const qString = createParams(args);
  return axios.get(`${API_GPX}/reports/route?${qString}`);
};

const getReportStops = (args) => {
  const qString = createParams(args);

  return axios.get(`${API_GPX}/reports/stops?${qString}`);
};

export const getCompleteLogs =
  (args, callback, callbackProcess) => (dispatch) => {
    axios.all([getReportRoutes(args), getReportStops(args)]).then(
      axios.spread((routes, stops) => {
        if (routes && stops) {
          callbackProcess();
          generateCompleteLogs(
            routes.data,
            stops.data,
            args.vehicles,
            dispatch,
            callback
          );
        }
      })
    );
  };

export const getOverspeedPositions = (events, dispatch, callback) => {
  let qString = "?";
  for (let i = 0; i < events.length; i++) {
    qString += `&id=${events[i].positionId}`;
  }

  axios
    .get(`${API_GPX}/positions${qString}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_OVERSPEED_POSITIONS,
          payload: res.data,
        });
        callback();
      }
    })
    .catch((e) => console.log(e));
};

export const getOverspeed = (args, callback, callbackProcess) => (dispatch) => {
  const dates = getISOOffSet(args);

  let qString = `type=${args.type}&from=${dates.from}&to=${dates.to}`;
  for (let i = 0; i < args.devices.length; i++) {
    qString += `&deviceId=${args.devices[i]}`;
  }

  axios
    .get(`${API_GPX}/reports/events?${qString}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_OVERSPEED,
          payload: res.data,
        });
        getOverspeedPositions(res.data, dispatch, callback);
      }
    })
    .catch((e) => console.log(e));
};

export const dispatchVehiclesToReports = (vehicles) => (dispatch) => {
  dispatch({ type: DISPATCH_VEHICLES_TO_REPORT, payload: vehicles });
};

export const dispatchSelectedVehiclesToReport = (ids) => (dispatch) => {
  dispatch({ type: DISPATCH_SELECTED_VEHICLES_TO_REPORT, payload: ids });
};

export const dispatchResetReportData = () => (dispatch) => {
  dispatch({
    type: RESET_REPORT_DATA,
    payload: [],
  });
};

export const getStopLogs = (args, callback) => (dispatch) => {
  const dates = getISOOffSet(args);

  let qString = `from=${dates.from}&to=${dates.to}`;
  for (let i = 0; i < args.devices.length; i++) {
    qString += `&deviceId=${args.devices[i]}`;
  }

  axios
    .get(`${API_GPX}/reports/stops?${qString}`)
    .then((res) => {
      if (res.status === 200) {
        // dispatch({
        //   type: GET_STOP_LOGS,
        //   payload: res.data,
        // });
        // if (typeof callback === "function") {
        //   callback();
        // }
        generateStopLogs(res.data, dispatch, callback);
      }
    })
    .catch((e) => console.log(e));
  // return;
};

export const getSummaryReport = (args, callback) => (dispatch) => {
  const dates = getISOOffSet(args);

  let qString = `from=${dates.from}&to=${dates.to}`;
  for (let i = 0; i < args.devices.length; i++) {
    qString += `&deviceId=${args.devices[i]}`;
  }

  axios
    .get(`${API_GPX}/reports/summary?${qString}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_SUMMARY_REPORT,
          payload: res.data,
        });
        callback();
      }
    })
    .catch((e) => console.log(e));
};

export const getAlertsReport = (args, callback) => (dispatch) => {
  const dates = getISOOffSet(args);

  let qString = `from=${dates.from}&to=${dates.to}`;
  for (let i = 0; i < args.devices.length; i++) {
    qString += `&deviceId=${args.devices[i]}`;
  }

  const getPositions = () => axios.get(`${API_GPX}/reports/route?${qString}`);
  const getAlerts = () => axios.get(`${API_GPX}/reports/events?${qString}`);

  axios.all([getPositions(), getAlerts()]).then(
    axios.spread((positions, alerts) => {
      if (positions.status === 200 && alerts.status === 200) {
        const p = positions.data,
          a = alerts.data.filter((a) => a.positionId > 0);
        const rep = a.map((alert) => {
          const pIndex = p.findIndex((pos) => pos.id === alert.positionId);
          const position = p[pIndex];

          alert.typeString = alert.type
            .split(/(?=[A-Z])/)
            .join(" ")
            .replace("device", "")
            .toUpperCase();
          alert.longitude = position.longitude;
          alert.latitude = position.latitude;
          alert.date = moment(alert.serverTime).format("YYYY-MM-DD");
          alert.time = moment(alert.serverTime).format("HH:mm:ss");
          alert.position = position;

          return alert;
        });

        const lonLat = rep.map((alert) => {
          return {
            positionId: alert.positionId,
            lat: alert.latitude,
            lon: alert.longitude,
          };
        });

        axios
          .post(`${API_LANDMARK}/many`, { coordinates: lonLat })
          .then((res) => {
            if (res.status === 200) {
              const landmarks = res.data;
              dispatch({
                type: GET_ALERTS,
                payload: rep.map((alert) => {
                  const lIndex = landmarks.findIndex(
                    (landmark) => landmark.positionId === alert.positionId
                  );
                  const landmark = landmarks[lIndex];
                  let l = `${landmark.distanceInMeter}m`;
                  if (landmark.direction !== "") {
                    l += ` ${landmark.direction}`;
                  }
                  l += ` From ${landmark.landmarkName}`;
                  alert.landmark = l;

                  return alert;
                }),
              });

              callback();
            }
          });
      }
    })
  );
};

export const getPositionsOfAlerts = (args, callback, dispatch) => {
  const dates = getISOOffSet(args);
  const positionsLength = args.positionIds.length;

  let qString = `from=${dates.from}&to=${dates.to}`;
  for (let i = 0; i < positionsLength; i++) {
    qString += `&id=${args.positionIds[i]}`;
  }

  axios
    .get(`${API_GPX}/positions?${qString}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: GET_ALERTS_POSITIONS,
          payload: res.data,
        });
        callback();
      }
    })
    .catch((e) => console.log(e));
};

export const getAllLandmarks = (args, callback) => (dispatch) => {
  const params = getReportLandmarkArgs(args);

  axios.post(`${API_LANDMARK}/many`, params).then((res) => {
    if (res.status === 200) {
      console.log(res.data);

      callback();
    }
  });
};

export const getGeofenceP2PAlert =
  (args, callback, callbackProcess) => (dispatch) => {
    const { vehicles, devices, geofences, from, to } = args;

    let eUrl = `${API_GPX}/reports/events?`;
    for (let i = 0; i < devices.length; i++) {
      eUrl += `deviceId=${devices[i]}&`;
    }
    eUrl += `type=geofenceEnter&type=geofenceExit&from=${from}T00:00:00Z&to=${to}T00:00:00Z`;
    let alerts = [];
    axios
      .get(eUrl)
      .then((res) => {
        let pUrl = `${API_GPX}/positions?`;
        if (res.status === 200) {
          alerts = res.data;
          const alertIds = extractIdsWithKey(alerts, "positionId");
          for (let j = 0; j < alertIds.length; j++) {
            pUrl += `id=${alertIds[j]}`;
            if (j < alertIds.length - 1) pUrl += "&";
          }
          return axios.get(pUrl);
        }
      })
      .then((res) => {
        if (res.status === 200) {
          callbackProcess();
          generateGeofenceP2P(
            vehicles,
            alerts,
            res.data,
            geofences,
            dispatch,
            callback
          );
        }
      })
      .catch((err) => console.log(err));
  };

export const getGeofence = (args, callback) => (dispatch) => {
  const { vehicles, devices, geofences, from, to } = args;
  let url = `${API_GPX}/reports/events?`;
  for (let i = 0; i < devices.length; i++) {
    url += `deviceId=${devices[i]}&`;
  }
  url += `type=geofenceEnter&type=geofenceExit&from=${from}T00:00:00Z&to=${to}T00:00:00Z`;

  axios
    .get(url)
    .then((res) => {
      if (res.status === 200) {
        const report = res.data.map((geo) => {
          const vIdx = vehicles.findIndex((d) => d.id === geo.deviceId);
          const gIdx = geofences.findIndex((g) => g.id === geo.geofenceId);
          geo.deviceName = vehicles[vIdx].name;
          geo.geofence = geofences[gIdx].name;
          geo.datetime = moment(geo.serverTime).format("YYYY-MM-DD HH:mm:ss");
          return geo;
        });
        dispatch({
          type: GET_GEOFENCE_REPORT,
          payload: report,
        });
      }
      callback();
    })
    .catch((err) => console.log(err));
};
