import React from "react";
import { connect } from "react-redux";
import { Popup } from "react-mapbox-gl";

import moment from "moment";

const handleCreatePopupSubheader = (vehicle, mapView, stop) => {
  if (mapView == 1) {
    return vehicle.position && vehicle.position.attributes.motion ? (
      <React.Fragment>
        {`Moving ${Math.round(vehicle.position.speed * 1.852)} km/h`}
        <br />
        {`as of ${moment(vehicle.position.deviceTime).format(
          "hh:mm A dddd MM/DD/YYYY"
        )}`}
      </React.Fragment>
    ) : (
      handleCreateSubheaderStopped(stop)
    );
  } else {
    return vehicle.position
      ? `as of ${moment(vehicle.position.deviceTime).format(
          "hh:mm A dddd MM/DD/YYYY"
        )}`
      : null;
  }
};

const handleCreateSubheaderStopped = stop => {
  const stopFrom = stop ? stop.startTime : null;
  const stopTo = stop ? stop.endTime : null;
  return (
    <React.Fragment>
      {"Stopped"}
      <br />
      {stop && stop.deviceId
        ? `${moment(stopFrom).format("HH:mm A")} - ${moment(stopTo).format(
            "HH:mm A"
          )}`
        : "--:--:-- - --:--:--"}{" "}
      {`${moment(stopFrom).format("dddd MM/DD/YYYY")}`}
    </React.Fragment>
  );
};

const handleGetLandmarkContent = landmark => {
  let str = "";

  str += landmark.distanceInMeter > 0 ? `${landmark.distanceInMeter}m ` : "";
  str += landmark.distanceInMeter > 0 ? "From" : "";
  str +=
    landmark.direction && landmark.distanceInMeter > 0
      ? `${landmark.direction} `
      : "";
  str += ` ${landmark.landmarkName}`;

  return str;
};

const handleCreatePlaceContent = place => {
  let str = "(";

  str += place.distanceInKilometer > 0 ? `${place.distanceInKilometer}km ` : "";
  str +=
    place.direction && place.distanceInKilometer > 0
      ? `${place.direction} `
      : "";
  str += place.distanceInKilometer > 0 ? "From" : "";
  str += ` ${place.place}`;
  str += ")";
  return str;
};

const MapVehiclePopup = ({ vehicle, lonlat, stop, mapView }) => {
  const subheader = handleCreatePopupSubheader(vehicle, mapView, stop);

  return (
    <Popup coordinates={lonlat} anchor="bottom">
      <div style={{ maxWidth: "300px" }}>
        <strong>{vehicle.name}</strong>

        {!subheader ? null : (
          <React.Fragment>
            <br />
            <strong>{subheader}</strong>
          </React.Fragment>
        )}
        {vehicle.position &&
        vehicle.position.valid === false &&
        vehicle.position.speed < 3 ? (
          <React.Fragment>
            <br />
            {`[No GPS Data, location may not be accurate]`}
          </React.Fragment>
        ) : null}
        {vehicle.landmark ? (
          <React.Fragment>
            <br />
            {handleGetLandmarkContent(vehicle.landmark)}
            <br />
            {handleCreatePlaceContent(vehicle.landmark.place)}
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    </Popup>
  );
};

const mapStateToProps = (state, { vehicle }) => {
  const stopIdx = state.vehicles.stops.findIndex(
    stop => stop.deviceId === vehicle.id
  );
  const stop = state.vehicles.stops[stopIdx];

  return {
    stop: stopIdx > -1 ? stop : null,
    mapView: state.settings.mapView
  };
};

export default connect(mapStateToProps, {})(MapVehiclePopup);
