import React from "react";
import { connect } from "react-redux";

import Reports from "../Reports/Reports";
import Main from "../Layouts/Main";

import { openCloseDrawer } from "../../actions/settingsAction";

const Report = ({ open, openCloseDrawer }) => {
  return (
    <Main>
      <div>
        <Reports />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => ({
  open: state.settings.openDrawer,
});

export default connect(mapStateToProps, { openCloseDrawer })(Report);
