import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ReactMapboxGL, { ZoomControl } from "react-mapbox-gl";
import DrawControl from "react-mapbox-gl-draw";
import { MAP_ACCESS_TOKEN } from "../../utils/Constants";

import AppWrapper from "../Layouts/AppWrapper";
import Main from "../Layouts/Main";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import Snackbar from "@material-ui/core/Snackbar";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { saveGeofence, updateGeofence } from "../../actions/geofenceAction";

import { openLoader, closeLoader } from "../../actions/dialogAction";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={`${classes[variant]} ${className}`}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={`${classes.icon} ${classes.iconVariant}`} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

const Map = ReactMapboxGL({
  accessToken: MAP_ACCESS_TOKEN,
});

const useStyles = makeStyles((theme) => ({
  button: {
    position: "absolute",
    zIndex: 5,
    marginRight: 0,
    top: "145px",
    left: "10px",
  },
  buttonRoot: {
    minWidth: "30px",
    padding: 0,
  },
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const genereateGeofence = (feature) => {
  const { geometry } = feature;
  const { coordinates, type } = geometry;
  let area = `${type.toUpperCase()}((`;
  for (let i = 0, len = coordinates.length; i < len; i++) {
    for (let x = 0, xlen = coordinates[i].length; x < xlen; x++) {
      if (x === xlen - 1) {
        area += `${coordinates[i][x][1]} ${coordinates[i][x][0]}`;
      } else {
        area += `${coordinates[i][x][1]} ${coordinates[i][x][0]},`;
      }
    }
  }
  area += "))";

  return area;
};

const generateCoordinates = ({ area, name }) => {
  const POL = "POLYGON";
  const CIR = "CIRCLE";

  const type = area.includes(POL) ? POL : CIR;
  let strArea = area.replace(type, "");
  let coordinates = [];
  let radius;
  if (type === POL) {
    strArea = strArea.replace("((", "");
    strArea = strArea.replace("))", "");

    let arrArea = strArea.split(",");
    const latLon = arrArea.map((arr) => {
      const ll = arr.trimStart().split(" ");
      return [parseFloat(ll[1]), parseFloat(ll[0])];
    });

    coordinates = latLon;
  } else {
    strArea = strArea.replace("(", "");
    strArea = strArea.replace(")", "");
    let circleProps = strArea.split(",");
    const latLon = circleProps[0].trimStart().split(" ");
    radius = parseFloat(circleProps[1]);
    coordinates = [parseFloat(latLon[1]), parseFloat(latLon[0])];
  }

  return { name, type, coordinates, radius };
};

function Geofence({
  center,
  saveGeofence,
  updateGeofence,
  geo,
  openLoader,
  closeLoader,
  groupId,
}) {
  const classes = useStyles();
  const drawCont = useRef(null);
  const [pGeo, setPGeo] = useState(null);
  const [geofence, setGeofence] = useState(null);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    content: "",
    variant: "info",
  });
  const [newGeofence, setNewGeofence] = useState({
    id: null,
    name: "",
    description: "",
    area: "",
    calendarId: 0,
  });
  const [mCenter, setMCenter] = useState(null);
  const [update, setUpdate] = useState(false);

  const prevPGeo = usePrevious(pGeo);
  const prevGeofence = usePrevious(geofence);
  const prevDrawCont = usePrevious(drawCont);

  useEffect(() => {
    let feat = null;
    if (pGeo !== geo) {
      setPGeo(geo);
    }

    if (geo) {
      if (prevPGeo !== pGeo) {
        feat = generateCoordinates(geo);
        if (!drawCont.current && geo) {
          const feature = {
            id: geo.id,
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [feat.coordinates],
            },
          };
          setGeofence(feature);
          setNewGeofence({
            id: geo.id,
            name: feat.name,
            description: geo.description,
            area: genereateGeofence(feature),
            calendarId: 0,
          });
          setMCenter(feat.coordinates[0]);
        }
      }
    }

    if (drawCont.current && prevDrawCont === drawCont) {
      if (geo) {
        if (prevGeofence && prevGeofence.id === geofence.id) {
          drawCont.current.draw.add(geofence);
        }

        if (prevPGeo !== geo) {
          feat = generateCoordinates(geo);
          drawCont.current.draw.deleteAll();

          const feature = {
            id: geo.id,
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [feat.coordinates],
            },
          };
          setGeofence(feature);
          setNewGeofence({
            id: geo.id,
            name: feat.name,
            description: geo.description,
            area: genereateGeofence(feature),
            calendarId: 0,
          });
          setMCenter(feat.coordinates[0]);
        }
      }
    }
  });
  // , [geofence, newGeofence, openAlert, mCenter, update]
  const onDrawCreate = ({ features }) => {
    const feat = features[0];
    genereateGeofence(feat);

    const ids = drawCont.current.draw
      .getAll()
      .features.filter((f) => f.id !== feat.id)
      .map((f) => f.id);
    if (drawCont.current) {
      drawCont.current.draw.delete(ids);
      setGeofence(feat);
    }
  };

  const onDrawUpdate = ({ features }) => {
    setGeofence(features[0]);
  };

  const onDrawDelete = (arg) => {
    console.log(drawCont);
    if (drawCont.current) {
      drawCont.current.draw.deleteAll();
    }
    setGeofence(null);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSaveGeofenceCallback = (isOpen, isSuccess) => {
    closeLoader();
    setOpenAlert({
      open: isOpen,
      content: isSuccess
        ? "Geofence was saved successfuly!"
        : "There is an error saving Geofence!",
      variant: isSuccess ? "success" : "error",
    });

    setOpen(false);
    if (!geo) {
      drawCont.current.draw.deleteAll();

      setNewGeofence({
        id: null,
        name: "",
        description: "",
        area: "",
        calendarId: 0,
      });

      setGeofence(null);
    }
  };

  const handleSaveGeofence = () => {
    openLoader();
    if (newGeofence.id) {
      updateGeofence(newGeofence, handleSaveGeofenceCallback);
    } else {
      saveGeofence(newGeofence, groupId, handleSaveGeofenceCallback);
    }
  };

  const handleOnInputChange = (e) =>
    setNewGeofence({
      ...newGeofence,
      [e.target.name]: e.target.value,
    });

  const handleCloseAlert = () => {
    setOpenAlert({
      ...openAlert,
      open: false,
    });
  };

  return (
    <AppWrapper>
      <Main>
        <div>
          {geofence === null ? (
            ""
          ) : (
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              classes={{ root: classes.buttonRoot }}
              onClick={() => {
                setNewGeofence({
                  ...newGeofence,
                  area: genereateGeofence(geofence),
                });
                setOpen(true);
              }}
            >
              <SaveIcon />
            </Button>
          )}
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={openAlert.open}
            autoHideDuration={5000}
            onClose={handleCloseAlert}
          >
            <MySnackbarContentWrapper
              onClose={handleCloseAlert}
              variant={openAlert.variant}
              message={openAlert.content}
            />
          </Snackbar>
          <Dialog
            onClose={handleCloseDialog}
            aria-labelledby="simple-dialog-title"
            open={open}
          >
            <DialogTitle>New Geofence</DialogTitle>
            <DialogContent>
              <TextField
                name="name"
                id="txtGeofenceName"
                label="Geofence Name"
                margin="normal"
                variant="outlined"
                fullWidth
                value={newGeofence.name}
                onChange={handleOnInputChange}
              />
              <TextField
                name="description"
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows="4"
                margin="normal"
                variant="outlined"
                fullWidth
                value={newGeofence.description}
                onChange={handleOnInputChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSaveGeofence} color="primary" autoFocus>
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <Map
            style="mapbox://styles/mapbox/streets-v9" // eslint-disable-line
            containerStyle={{
              height: "100vh",
              width: "100vw",
            }}
            center={mCenter ? mCenter : center}
            zoom={[17]}
          >
            <ZoomControl />
            <DrawControl
              ref={(drawControl) => {
                drawCont.current = drawControl;
                setUpdate(true);
              }}
              onDrawCreate={onDrawCreate}
              onDrawUpdate={onDrawUpdate}
              onDrawDelete={onDrawDelete}
              displayControlsDefault={false}
              controls={{
                polygon: geofence === null ? true : false,
                trash: true,
              }}
            />
          </Map>
        </div>
      </Main>
    </AppWrapper>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { geofences } = state.geofences;
  const geoId = parseInt(ownProps.match.params.id);
  const idx = geofences.findIndex((geo) => geo.id === geoId);

  return {
    center: state.map.center,
    groupId: state.groups.groupIds.length > 0 ? state.groups.groupIds[0] : null,
    isAdmin: state.user.user.administrator,
    geo: idx > -1 ? geofences[idx] : null,
  };
};

export default connect(mapStateToProps, {
  saveGeofence,
  updateGeofence,
  openLoader,
  closeLoader,
})(Geofence);
