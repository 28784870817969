import { GET_USER, LOGOUT_USER, GET_USERS } from "../actions/type";
import { USER_ROLES } from "../utils/Constants";

const initialState = {
  user: {},
  isAuth: false,
  isLogout: false,
  users: [],
  role: null,
};

const getUserRole = (user) => {
  const { administrator, readonly, userLimit, deviceReadOnly } = user;
  if (administrator === true) {
    return USER_ROLES.admin;
  } else if (!administrator && !readonly && !deviceReadOnly && userLimit > 0) {
    return USER_ROLES.manager;
  } else if (readonly || userLimit === 0) {
    return USER_ROLES.readOnly;
  } else if (deviceReadOnly) {
    return USER_ROLES.deviceReadOnly;
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER:
    case LOGOUT_USER:
      return {
        ...state,
        user: action.payload ? action.payload : {},
        isAuth: action.payload ? true : false,
        role: action.payload ? getUserRole(action.payload) : null,
        isLogout: true,
        users: [],
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    default:
      return state;
  }
}
