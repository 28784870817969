import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Checkbox from "@material-ui/core/Checkbox";

import { deleteGeofence } from "../../actions/geofenceAction";
import { closeDialog, openLoader } from "../../actions/dialogAction";
import {
  saveUserGeofencePermissions,
  deleteUserGeofencePermissions,
} from "../../actions/permissionsAction";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "flex-start",
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: "1px solid #ccc",
    "&:first-child": {
      borderTop: "1px solid #ccc",
    },
  },
}));

const GeofenceMenu = withRouter(
  connect(null, { closeDialog, deleteGeofence, openLoader })((props) => {
    const {
      geofence,
      deleteGeofence,
      closeDialog,
      history,
      openLoader,
      deleteCallback,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleClick(event) {
      setAnchorEl(event.currentTarget);
    }

    function handleClose(to, view) {
      setAnchorEl(null);
    }

    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={`geofence-menu-${geofence.id}`}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => {
            handleClose();
          }}
        >
          <MenuItem
            onClick={() => {
              closeDialog();
              history.push(`/geofence/${geofence.id}`);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              openLoader();
              handleClose();
              deleteGeofence(geofence.id, deleteCallback);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  })
);

function Geofence({ geofence, deleteCallback, userGeofence, dialog }) {
  const classes = useStyles();
  const [checked, setChecked] = useState(
    userGeofence ? geofence.isUserGeofence : false
  );

  const handleOnChange = (e) => {
    const { data } = dialog;
    if (e.target.checked) {
      saveUserGeofencePermissions(data.userId, geofence.id);
    } else {
      deleteUserGeofencePermissions(data.userId, geofence.id);
    }
    setChecked(e.target.checked);
  };

  return (
    <ListItem classes={{ root: classes.root }}>
      <ListItemText primary={geofence.name} />
      {userGeofence ? (
        <Checkbox checked={checked} onChange={handleOnChange} />
      ) : (
        <GeofenceMenu geofence={geofence} deleteCallback={deleteCallback} />
      )}
    </ListItem>
  );
}

const mapStateToProps = (state) => ({
  dialog: state.dialog,
});

export default connect(mapStateToProps, {})(Geofence);
