import {
  SET_POPUP,
  SET_MAP_CENTER,
  SET_LIVE_TRACK,
  RESET_MAP
} from "../actions/type";

const initialState = {
  isPopup: false,
  popupContent: {},
  center: [],
  zoom: [15],
  isLiveTrack: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_POPUP:
      return {
        ...state,
        isPopup: action.payload.isPopup,
        popupContent: action.payload.popupContent
      };
    case SET_MAP_CENTER:
      return {
        ...state,
        center: action.payload.center,
        zoom: action.payload.zoom ? action.payload.zoom : state.zoom
      };
    case SET_LIVE_TRACK:
      return {
        ...state,
        isLiveTrack: action.payload
      };
    case RESET_MAP:
      return {
        isPopup: false,
        popupContent: {},
        center: [],
        zoom: [15],
        isLiveTrack: false
      };
    default:
      return state;
  }
}
