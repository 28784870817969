import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

import moment from "moment";
import { connect } from "react-redux";

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          borderRadius: 0,
        },
      },
    },
  });

const columns = [
  {
    name: "deviceName",
    label: "Device",
    options: {
      filterType: "textField",
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
    },
  },
  {
    name: "geofence",
    label: "Geofence",
    options: {
      filterType: "textField",
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
    },
  },
  {
    name: "gpxType",
    label: "Type",
    options: {
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
    },
  },
  {
    name: "datetime",
    label: "Date",
    options: {
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      filter: false,
    },
  },
];

const Geofence = (props) => {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={"Report - Geofence"}
        data={props.report}
        columns={columns}
        options={{
          print: false,
          selectableRows: false,
          responsive: "scrollMaxHeight",
          downloadOptions: {
            filename: `GeofencePointToPoint_${moment().format(
              "YYYYMMDDHHmmss"
            )}.csv`,
          },
          textLabels: {
            pagination: {
              rowsPerPage: "Per Page",
            },
          },
        }}
      />
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  report: state.reports.geofences,
});

export default connect(mapStateToProps)(Geofence);
