import { combineReducers } from "redux";
import vehicleReducer from "./vehicleReducer";
import geofenceReducer from "./geofenceReducer";
import socketReducer from "./socketReducer";
import userReducer from "./userReducer";
import serverReducer from "./serverReducer";
import settingsReducer from "./settingsReducer";
import groupReducer from "./groupReducer";
import reportsReducer from "./reportsReducer";
import mapReducer from "./mapReducer";
import dialogReducer from "./dialogReducer";

export default combineReducers({
  vehicles: vehicleReducer,
  socket: socketReducer,
  user: userReducer,
  server: serverReducer,
  settings: settingsReducer,
  groups: groupReducer,
  reports: reportsReducer,
  geofences: geofenceReducer,
  map: mapReducer,
  dialog: dialogReducer
});
