import { UPDATE_DEVICES, UPDATE_POSITIONS, UPDATE_EVENTS, CLOSE_EVENT } from "../actions/type";

const initialState = {
  devices: [],
  positions: [],
  events: [],
  event: []
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DEVICES:
      return Object.assign(
        {},
        {
          ...state,
          devices: [...action.devices]
        }
      );
    case UPDATE_POSITIONS:
      return Object.assign(
        {},
        {
          ...state,
          positions: [...action.positions]
        }
      );
    case UPDATE_EVENTS:
      return Object.assign(
        {},
        {
          ...state,
          events: [...state.events, ...action.events],
          event: [...action.events]
        }
      );
    case CLOSE_EVENT:
      return {
        ...state,
        events: state.events.map(event => {
          if (event.id === action.payload) {
            event.closed = true;
          }
          return event;
        })
      };
    default:
      return state;
  }
}

export default rootReducer;
