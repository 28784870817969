import React from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { setFilter } from "../../actions/settingsAction";
import DrawerMain from "../Layouts/DrawerMain";
import Vehicle from "./Fleet/Vehicle";
import FilterVehicles from "./Fleet/FilterVehicles";
import FleetChips from "./Fleet/FleetChips";

import { List, Typography } from "@material-ui/core";
import { MOVING, STOPPED, OFFLINE } from "../../utils/Constants";

const VehicleListDrawer = ({ vehicles, history, setFilter }) => {
  const handleRedirect = to => {
    history.push(to);
  };

  const handleCreateVehiclesList = status => {
    return vehicles
      .sort(function(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .filter(v => v.gpxStatus === status)
      .map(v => {
        return <Vehicle key={v.id} v={v} redirect={handleRedirect} />;
      });
  };

  const handleFilter = args => {
    setFilter(args);
  };

  return (
    <DrawerMain>
      <Typography
        variant="h6"
        gutterBottom
        style={{ padding: "8px 8px 0 8px" }}
      >
        Fleet
      </Typography>
      <FilterVehicles filter={handleFilter} />
      <List>
        <FleetChips filter={handleFilter} />
      </List>
      <List>{handleCreateVehiclesList(MOVING)}</List>
      <List>{handleCreateVehiclesList(STOPPED)}</List>
      <List>{handleCreateVehiclesList(OFFLINE)}</List>
    </DrawerMain>
  );
};

VehicleListDrawer.propTypes = {
  setFilter: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    open: state.settings.openDrawer,
    vehicles: state.vehicles.filteredVehicles
  };
};

const mapDispatchToProps = {
  setFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleListDrawer);
