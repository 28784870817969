import React from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

import { connect } from "react-redux";

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          borderRadius: 0,
        },
      },
      MUIDataTablePagination: {
        caption: {
          // display: "none"
        },
      },
    },
  });

const columns = [
  {
    name: "deviceName",
    label: "Vehicle",
  },
  {
    name: "sDate",
    label: "Date From",
  },
  {
    name: "sTime",
    label: "Time From",
  },
  {
    name: "eDate",
    label: "Date To",
  },
  {
    name: "eTime",
    label: "Time To",
  },
  {
    name: "landmark",
    label: "Nearest reference point",
  },
  {
    name: "place",
    label: "Distance reference to nearest landmark",
  },
  {
    name: "latitude",
    label: "Lattitude",
  },
  {
    name: "longitude",
    label: "Longitude",
  },
];

const StopLogs = (props) => {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={"Report - Stop Logs"}
        data={props.stopLogs}
        columns={columns}
        options={{
          print: false,
          selectableRows: false,
          responsive: "scrollMaxHeight",
          downloadOptions: {
            filename: "StopLogs_" + moment().format("YYYYMMDDHHmmss") + ".csv",
          },
          textLabels: {
            pagination: {
              rowsPerPage: "Per Page",
            },
          },
        }}
      />
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  stopLogs: state.reports.stopLogs,
});

export default connect(mapStateToProps)(StopLogs);
