import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import { setGroup } from "../../../actions/groupsAction";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  inputClassLabel: {
    transform: "translate(14px, 10px) scale(1)"
  },
  inputClass: {
    padding: ".5rem"
  },
  w100: {
    width: "100%",
    marginTop: "0"
  },
  inputSelectLabel: {
    transform: "translate(14px, 10px) scale(1)"
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  inputSelect: {
    padding: ".5rem"
  },
  expSummary: {
    padding: "0 10px 0 10px"
  },
  expDetails: {
    flexDirection: "column",
    padding: "8px 10px 10px"
  },
  expandIcon: {
    marginRight: "-8px"
  }
}));

const createGroupsSelection = (classes, settings, groups, setGroup, filter) => {
  return settings.isAdmin || settings.isMonitoring ? (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel
        htmlFor="outlined-age-native-simple"
        classes={{ outlined: classes.inputSelectLabel }}
      >
        Company ...
      </InputLabel>
      <Select
        native
        value={settings.group}
        input={
          <OutlinedInput
            name="age"
            labelWidth={85}
            id="outlined-age-native-simple"
          />
        }
        classes={{ select: classes.inputSelect }}
        onChange={e => {
          setGroup(e.target.value);
          filter({
            isFilter: e.target.value > 0 ? true : false,
            by: "company",
            keyword: e.target.value
          });
        }}
      >
        <option value="0">All</option>
        {groups.map(g => (
          <option key={g.id} value={g.id}>
            {g.name}
          </option>
        ))}
      </Select>
    </FormControl>
  ) : null;
};

const FilterVehicles = ({ settings, groups, filter, setGroup }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{ root: classes.expSummary, expandIcon: classes.expandIcon }}
      >
        <Typography className={classes.heading}>Filter</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.expDetails }}>
        {createGroupsSelection(classes, settings, groups, setGroup, filter)}
        <TextField
          id="vehicle"
          label="Search Vehicle ..."
          InputLabelProps={{
            classes: {
              root: classes.inputClassLabel
            }
          }}
          InputProps={{
            classes: { input: classes.inputClass }
          }}
          className={classes.w100}
          margin="normal"
          variant="outlined"
          onChange={e => {
            filter({
              isFilter: e.target.value !== "" ? true : false,
              by: "name",
              keyword: e.target.value
            });
          }}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const mapStateToProps = state => ({
  settings: state.settings,
  groups: state.groups.groups
});

export default connect(
  mapStateToProps,
  { setGroup }
)(FilterVehicles);
