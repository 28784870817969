import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import moment from "moment";

import { connect } from "react-redux";

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          borderRadius: 0,
        },
      },
      MUIDataTablePagination: {
        caption: {
          // display: "none"
        },
      },
    },
  });

const completeLogsColumns = [
  {
    name: "deviceName",
    label: "Device",
    options: {
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      customFilterListRender: (v) => `Date: ${v}`,
    },
  },
  {
    name: "time",
    label: "Time",
    options: {
      sort: false,
      filter: false,
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
    },
  },
  {
    name: "speedText",
    label: "Speed (kph)",
    options: {
      filterType: "textField",
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      filterOptions: {
        logic(speed, filters) {
          return speed < filters;
        },
      },
      customFilterListRender: (v) => `Speed > ${v}`,
    },
  },
  {
    name: "landmark",
    label: "Landmark",
    options: {
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      filterType: "textField",
      customFilterListRender: (v) => `Landmark: ${v}`,
    },
  },
  {
    name: "place",
    label: "Place",
    options: {
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      filterType: "textField",
      customFilterListRender: (v) => `Place: ${v}`,
    },
  },
  {
    name: "latitude",
    label: "Latitude",
    options: {
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "longitude",
    label: "Longitude",
    options: {
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      filter: false,
      sort: false,
      display: false,
    },
  },
];

const CompleteLogs = (props) => {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={"Report - Complete Logs"}
        data={props.completeLogs}
        columns={completeLogsColumns}
        responsive="stacked"
        options={{
          print: false,
          selectableRows: "none",
          responsive: "scrollMaxHeight",
          downloadOptions: {
            filename:
              "CompleteLogs_" + moment().format("YYYYMMDDHHmmss") + ".csv",
            filterOptions: {
              useDisplayedRowsOnly: true,
            },
          },
          textLabels: {
            pagination: {
              rowsPerPage: "Per Page",
            },
          },
        }}
      />
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  completeLogs: state.reports.completeLogs,
  positions: state.reports.positions,
  stops: state.reports.stops,
  selectedVehicles: state.reports.selectedVehicles,
  vehicles: state.reports.vehicles,
});

export default connect(mapStateToProps)(CompleteLogs);
