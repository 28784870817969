import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import classnames from "classnames";

import { openCloseDrawer } from "../../actions/settingsAction";

const drawerWidth = 340;
const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  toolbarDrawer: theme.mixins.toolbar,
  drawerNav: {
    transition: theme.transitions.create("right", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    position: "absolute",
    zIndex: 1200,
    top: "64px",
    right: 0,
    [theme.breakpoints.down("sm")]: {
      top: "66px"
    }
  },
  drawerNavShift: {
    transition: theme.transitions.create("right", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    right: drawerWidth,
    [theme.breakpoints.down("sm")]: {
      top: "56px",
      right: 0
    }
  },
  drawerNavButtonRoot: {
    minWidth: "25px",
    backgroundColor: "#fff",
    borderRadius: "0"
  },
  drawerNavButton: {
    padding: "11px 4px",
    "&:hover": {
      backgroundColor: "#fff"
    }
  }
}));

function DrawerMain({ open, children, openCloseDrawer }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="right"
        open={open}
      >
        <div className={classes.toolbarDrawer} />
        {children}
      </Drawer>
      <div
        className={classnames(classes.drawerNav, {
          [classes.drawerNavShift]: open
        })}
      >
        <Button
          className={classes.drawerNavButton}
          classes={{ root: classes.drawerNavButtonRoot }}
          onClick={() => {
            openCloseDrawer(!open);
          }}
        >
          {open ? <Icon>chevron_right</Icon> : <Icon>chevron_left</Icon>}
        </Button>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = state => ({
  open: state.settings.openDrawer
});

export default connect(
  mapStateToProps,
  { openCloseDrawer }
)(DrawerMain);
