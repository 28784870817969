export const LAST_UPDATE_THRESHOLD = 30; // minutes
export const SPEED_THRESHOLD = 1.11; // knots
export const DISTANCE_THRESHOLD = 300; // meters

// Statuses
export const MOVING = "moving";
export const STOPPED = "stopped";
export const OFFLINE = "offline";
export const ONLINE = "online";
export const UNKNOWN = "unknown";

// Dialogs
export const DIALOG_CONTENT = {
  geofence: 1,
  user: 2,
  userGeofence: 3,
};

export const USER_ROLES = {
  admin: 1,
  manager: 2,
  readOnly: 3,
  deviceReadOnly: 4,
};

// API Link
export const API_GPX = "https://gpx.cloudph.net/api";
export const API_LANDMARK = "https://gps.cloudph.net/landmark/get/nearest";

// GOOGLE MAPS API KEY
export const GOOGLE_MAPS_API_KEY = "AIzaSyAkwcTYEBfzzkUqj7sj51H3JFKTrVv9B9w";

// Mapillary Client ID
export const MAPILLARY_CLIENT_ID =
  "YmZuX1J1bjYwTTlCbzN4SUFFQ3VhUTowYTAxZjhkNTliNmE3Yjkx";

// Map Token
export const MAP_ACCESS_TOKEN =
  "pk.eyJ1IjoiamhlMDEiLCJhIjoiY2pwZGtpNDZyMWZrcjNwbWtkNGVqMWlwMiJ9.iVT0VYSDA4RAucemSwRA3Q";
