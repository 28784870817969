import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Loader } from "google-maps";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import { GOOGLE_MAPS_API_KEY } from "../../utils/Constants";

import { closeStreetView } from "../../actions/dialogAction";

const loader = new Loader(GOOGLE_MAPS_API_KEY, { version: 3.41 });

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    height: "100%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function StreetView({ open, lat, lon, closeStreetView }) {
  const [googleMap, setGoogleMap] = useState(null);
  const [streetView, setStreetView] = useState(null);
  const container = useRef(null);
  const classes = useStyles();

  const prevGoogleMap = usePrevious(googleMap);

  useEffect(() => {
    if (!googleMap && open) {
      loadGoogleMap();
    }

    if (googleMap && googleMap === prevGoogleMap) {
      initializeStreetView();
    }
  });

  const loadGoogleMap = () => {
    loader.load().then((google) => {
      const sv = new google.maps.StreetViewPanorama(container.current, {
        position: { lat: 46.9171876, lng: 17.8951832 },
        pov: { heading: 0, pitch: 0 },
        zoom: 1,
      });

      setStreetView(sv);
      setGoogleMap(google);
    });
  };

  const initializeStreetView = () => {
    console.log(googleMap, container);

    if (container.current) {
    }
  };

  function handleClose() {
    closeStreetView();
  }
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Streetview
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent
        ref={container}
        className={classes.content}
      ></DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  open: state.dialog.streetView.open,
  lat: state.dialog.streetView.lat,
  lon: state.dialog.streetView.lon,
});

export default connect(mapStateToProps, { closeStreetView })(StreetView);
