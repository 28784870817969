import React from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

import { connect } from "react-redux";

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          borderRadius: 0,
        },
      },
      MUIDataTablePagination: {
        caption: {
          // display: "none"
        },
      },
    },
  });

const columns = [
  {
    name: "deviceName",
    label: "Device",
  },
  {
    name: "maxSpeed",
    label: "Max Speed (km/h)",
  },
  {
    name: "averageSpeed",
    label: "Ave. Speed (km/h)",
  },
  {
    name: "distance",
    label: "Distance (km)",
  },
  {
    name: "engineHours", // this depends on unit's acc sensor. 0 value if no acc sensor
    label: "Engine Hours",
  },
  {
    name: "fuelConsumed",
    label: "Total Fuel",
  },
];

const SummaryReport = (props) => {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={"Report - Summary Report"}
        data={props.summaryReport}
        columns={columns}
        options={{
          print: false,
          selectableRows: false,
          responsive: "scrollMaxHeight",
          downloadOptions: {
            filename:
              "SummaryReport_" + moment().format("YYYYMMDDHHmmss") + ".csv",
          },
          textLabels: {
            pagination: {
              rowsPerPage: "Per Page",
            },
          },
        }}
      />
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  summaryReport: state.reports.summaryReport,
});

export default connect(mapStateToProps)(SummaryReport);
