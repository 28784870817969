import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { ListItem, ListItemText } from "@material-ui/core";

import moment from "moment";
import { getNearestLandmark2 } from "../../../actions/mapActions";
import { openCloseDrawer } from "../../../actions/settingsAction";

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "flex-start",
    borderBottom: "1px solid #ccc",
    "&:first-child": {
      borderTop: "1px solid #ccc",
    },
    cursor: "pointer",
  },
}));

const generateRouteText = (route) => {
  const time = moment(route.fixTime).format("hh:mm:ss A");
  const upTo = route.lastSamePos
    ? moment(route.lastSamePos.fixTime).format("hh:mm:ss A")
    : null;
  if (route.isStop) {
    return `${moment(route.startTime).format("hh:mm:ss A")} - ${moment(
      route.endTime
    ).format("hh:mm:ss A")}`;
  } else {
    return `${time} ${route.lastSamePos ? `- ${upTo}` : ""}`;
  }
};

const VRoute = ({
  route,
  getNearestLandmark2,
  vehicle,
  open,
  openCloseDrawer,
  width,
}) => {
  const classes = useStyles();
  const mergePositionWithVehicle = () => {
    vehicle.position = route;
    vehicle.isPopup = true;
    return vehicle;
  };
  return (
    <ListItem
      classes={{ root: classes.root }}
      onClick={() => {
        if (width === "xs") {
          openCloseDrawer(!open);
        }
        getNearestLandmark2(mergePositionWithVehicle());
      }}
    >
      <ListItemText>{generateRouteText(route)}</ListItemText>
    </ListItem>
  );
};

const mapStateToProps = (state) => ({
  open: state.settings.openDrawer,
});

export default connect(mapStateToProps, {
  getNearestLandmark2,
  openCloseDrawer,
})(withWidth()(VRoute));
