import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getCompleteLogs,
  getOverspeed,
  dispatchVehiclesToReports,
  dispatchSelectedVehiclesToReport,
  dispatchResetReportData,
  getStopLogs,
  getSummaryReport,
  getAlertsReport,
  getPositionsOfAlerts,
  getAllLandmarks,
  getGeofenceP2PAlert,
  getGeofence,
} from "../../actions/reportsActions";

import { withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Spinner from "../Layouts/Spinner";
import ReportFilter from "./ReportFilter";

import CompleteLogs from "./Types/CompleteLogs";
import Overspeed80 from "./Types/Overspeed80";
import Overspeed100 from "./Types/Overspeed100";
import StopLogs from "./Types/StopLogs";
import SummaryReport from "./Types/SummaryReport";
import Events from "./Types/Events";
import GeofenceP2P from "./Types/GeofenceP2P";
import Geofence from "./Types/Geofence";

const style = (theme) => ({
  content: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  table: {
    flexGrow: 2,
    [theme.breakpoints.up("md")]: {
      padding: "0 0 0 5px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 0 0 0",
    },
  },
});

class Reports extends Component {
  state = {
    type: 0,
    isLoading: false,
    alertsPositionIds: [],
    isShowAlertAddress: false,
    args: {},
    loadingState: 0, // 1 - Gathering Data, 2 - Processing data, 0 - default
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.alertsPositionIds !== this.props.alertsPositionIds) {
      this.setState({ alertsPositionIds: this.props.alertsPositionIds });
    }
    if (prevProps.geofences !== this.props.geofences) {
      this.setState({ geofences: this.props.geofences });
    }
  }

  handleOnShowReport = (args) => {
    this.setState({
      isLoading: true,
      args,
      isShowAlertAddress: false,
      loadingState: 1,
    });
    this.props.dispatchResetReportData();

    const { type } = this.state;
    const iType = parseInt(type);

    if (iType === 1) {
      this.props.getCompleteLogs(
        args,
        this.handleCallbackOnShowReport,
        this.handleProcessingData
      );
    } else if (iType === 2 || iType === 3) {
      args.type = "deviceOverspeed";
      this.props.getOverspeed(
        args,
        this.handleCallbackOnShowReport,
        this.handleProcessingData
      );
    } else if (iType === 4) {
      this.props.getStopLogs(args, this.handleCallbackOnShowReport);
    } else if (iType === 5) {
      this.props.getSummaryReport(args, this.handleCallbackOnShowReport);
    } else if (iType === 6) {
      this.props.getAlertsReport(args, this.handleCallbackOnShowReport);
    } else if (iType === 7) {
      this.props.getGeofenceP2PAlert(
        args,
        this.handleCallbackOnShowReport,
        this.handleProcessingData
      );
    } else if (iType === 8) {
      this.props.getGeofence(args, this.handleCallbackOnShowReport);
    }
  };

  handleShowAddress = (args) => {
    this.props.getAllLandmarks(args, this.handleCallbackOnShowReport);
  };

  handleProcessingData = () => {
    this.setState({ loadingState: 2 });
  };

  handleShowAlertsPositions = () => {
    this.setState({ isLoading: true, isShowAlertAddress: true });
    const nArgs = {
      from: this.state.args.from,
      to: this.state.args.to,
      positionIds: this.state.alertsPositionIds,
    };

    const positionsLength = nArgs.positionIds.length;
    const maxLength = 200;
    const calls = Math.round(positionsLength / maxLength);
    const remainder = positionsLength % maxLength;
    const countCalls = remainder > 0 ? calls + 1 : calls;

    let iFrom = 0;
    let iTo = remainder > 0 ? maxLength : positionsLength;

    for (let i = 0; i < countCalls; i++) {
      const args = {
        from: nArgs.from,
        to: nArgs.to,
        positionIds: nArgs.positionIds.slice(iFrom, iTo + 1),
      };

      this.props.getPositionsOfAlerts(args, this.handleCallbackOnShowReport);

      iFrom = iTo + 1;
      if (i === countCalls - 2) {
        iTo = iFrom + remainder;
      } else {
        iTo = maxLength * (i + 2);
      }
    }
  };

  handleCallbackOnShowReport = () => {
    this.setState({ isLoading: false });
  };

  handleCallLoadingState = () => {
    this.setState({ isLoading: true });
  };

  handleOnSelectChangeInput = (e, f) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCreateReportSelection = () => {
    return (
      <ReportFilter
        handleOnShowReport={this.handleOnShowReport}
        handleSetType={this.handleOnSelectChangeInput}
        handleShowAddress={this.handleShowAddress}
      />
    );
  };

  handleGetReportTable = (type) => {
    if (type === 1) return <CompleteLogs />;
    if (type === 2) return <Overspeed80 />;
    if (type === 3) return <Overspeed100 />;
    if (type === 4) return <StopLogs />;
    if (type === 5) return <SummaryReport />;
    if (type === 6)
      return (
        <Events
          handleShowAlertsPositions={this.handleShowAlertsPositions}
          isShowAddress={this.state.isShowAlertAddress}
        />
      );

    if (type === 7) return <GeofenceP2P />;
    if (type === 8) return <Geofence />;
  };

  componentWillUnmount() {
    this.props.dispatchResetReportData();
  }

  render() {
    const { type, isLoading, loadingState } = this.state;

    return (
      <div style={{ padding: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Box component="div">{this.handleCreateReportSelection()}</Box>
          </Grid>
          <Grid item xs={12} md={10}>
            {!isLoading ? (
              <Box component="div" width={1}>
                {this.handleGetReportTable(parseInt(type))}
              </Box>
            ) : (
              <Dialog
                open={isLoading}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                maxWidth={"xs"}
              >
                <DialogContent>
                  <Spinner />
                  {loadingState === 1 ? (
                    <DialogContentText id="alert-dialog-description">
                      Gathering data ...
                    </DialogContentText>
                  ) : null}
                  {loadingState === 2 ? (
                    <DialogContentText id="alert-dialog-description">
                      Processing data. It may take longer if the data gathered
                      was too big.
                    </DialogContentText>
                  ) : null}
                </DialogContent>
              </Dialog>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

Reports.propTypes = {
  getCompleteLogs: PropTypes.func.isRequired,
  getOverspeed: PropTypes.func.isRequired,
  dispatchVehiclesToReports: PropTypes.func.isRequired,
  dispatchSelectedVehiclesToReport: PropTypes.func.isRequired,
  dispatchResetReportData: PropTypes.func.isRequired,
  getStopLogs: PropTypes.func.isRequired,
  getAlertsReport: PropTypes.func.isRequired,
  getPositionsOfAlerts: PropTypes.func.isRequired,
  getGeofenceP2PAlert: PropTypes.func.isRequired,
  getGeofence: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  company: state.groups.groups,
  alertsPositionIds: state.reports.alertsPositionIds,
});

export default connect(mapStateToProps, {
  getCompleteLogs,
  getOverspeed,
  dispatchVehiclesToReports,
  dispatchSelectedVehiclesToReport,
  dispatchResetReportData,
  getStopLogs,
  getSummaryReport,
  getAlertsReport,
  getPositionsOfAlerts,
  getAllLandmarks,
  getGeofenceP2PAlert,
  getGeofence,
})(withStyles(style)(Reports));
