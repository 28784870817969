import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { withRouter } from "react-router-dom";
import { ListItem, ListItemText } from "@material-ui/core";
import { getDuration, getGpxStatus } from "../../../utils/MapUtils";
import { MOVING, STOPPED, OFFLINE, ONLINE } from "../../../utils/Constants";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { setMapView, openCloseDrawer } from "../../../actions/settingsAction";
import { getNearestLandmark2, setLiveTrack } from "../../../actions/mapActions";
import {
  activateLiveTracking,
  deactivateLiveTracking,
} from "../../../actions/vehiclesActions";
import { openStreetView } from "../../../actions/dialogAction";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "flex-start",
    borderBottom: "1px solid #ccc",
    "&:first-child": {
      borderTop: "1px solid #ccc",
    },
  },
  onlineStatus: {
    backgroundColor: "#008000",
    color: "#fff3e0",
  },
  offlineStatus: {
    backgroundColor: "#ff1500",
    color: "#fff3e0",
  },
  unknownStatus: {
    backgroundColor: "#ff9800",
    color: "#fff3e0",
  },
  subheaderRoot: {
    margin: 0,
  },
  subheaderPrimary: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
  },
  itemLeft: {
    cursor: "pointer",
    flexGrow: 1,
  },
  iconLabel: {
    color: "#fff",
  },
  link: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

const VehicleMenu = withRouter((props) => {
  const { vehicle } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(to, view) {
    setAnchorEl(null);
    if (to && view) {
      if (view === 3) {
        props.openCloseDrawer(true);
        props.setLiveTrack(true);
        props.activateLiveTracking();
      }

      props.setMapView(view);
      props.history.push(to);
    }
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        classes={{ label: classes.iconLabel }}
      >
        <MoreVertIcon />
      </IconButton>
      {vehicle.status === ONLINE ? (
        <Menu
          id={`vehicle-menu-${vehicle.id}`}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => {
            handleClose("", false);
          }}
        >
          <MenuItem
            onClick={() => {
              localStorage.setItem("currentUrl", `/trips/${vehicle.id}`);
              localStorage.setItem("currentView", 2);
              handleClose(`/trips/${vehicle.id}`, 2);
            }}
          >
            Trip History
          </MenuItem>
          <MenuItem
            onClick={() => {
              localStorage.setItem("currentUrl", `/livetrack/${vehicle.id}`);
              localStorage.setItem("currentView", 3);
              handleClose(`/livetrack/${vehicle.id}`, 3);
            }}
          >
            Live Tracking
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              props.openStreetView();
            }}
          >
            Street View
          </MenuItem> */}
        </Menu>
      ) : (
        <Menu
          id={`vehicle-menu-${vehicle.id}`}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => {
            handleClose("", false);
          }}
        >
          <MenuItem
            onClick={() => {
              localStorage.setItem("currentUrl", `/trips/${vehicle.id}`);
              handleClose(`/trips/${vehicle.id}`, 2);
            }}
          >
            Trip History
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              props.openStreetView();
            }}
          >
            Street View
          </MenuItem> */}
        </Menu>
      )}
    </div>
  );
});

const subheaderOffline = (vehicle) => {
  return `Last Update: ${getDuration({
    date: vehicle.lastPositionUpdate,
  })} ago`;
};

const subheaderMoving = (vehicle) => {
  return `Moving ${Math.round(vehicle.position.speed * 1.852)} km/h`;
};

const subheaderStopped = (stop) => {
  const stopFrom = stop ? stop.startTime : null;
  const stopTo = stop ? stop.endTime : null;

  return (
    <div>
      {`Stopped: ${
        stop
          ? `${moment(stopFrom).format("HH:mm A")} - ${moment(stopTo).format(
              "HH:mm A"
            )}`
          : "--:--:-- - --:--:--"
      }`}{" "}
      {stop
        ? moment(stopFrom).format("dddd MM/DD/YYYY")
        : moment().format("dddd MM/DD/YYYY")}
    </div>
  );
};

const subheader = (vehicle, stop) => {
  if (vehicle.gpxStatus === OFFLINE) {
    return subheaderOffline(vehicle);
  } else {
    return vehicle.position &&
      vehicle.position.attributes &&
      vehicle.position.attributes.motion
      ? subheaderMoving(vehicle)
      : subheaderStopped(stop);
  }
};

const Vehicle = ({
  v,
  setMapView,
  getNearestLandmark2,
  setLiveTrack,
  open,
  openCloseDrawer,
  width,
  activateLiveTracking,
  deactivateLiveTracking,
  positions,
  stop,
  openStreetView,
}) => {
  const classes = useStyles();

  v = {
    ...v,
    position:
      positions.length > 1
        ? positions[positions.findIndex((pos) => pos.deviceId === v.id)]
        : {},
  };
  const status = v.gpxStatus; //getGpxStatus(v);
  const getBackgroundColor = () => {
    if (status === MOVING) {
      return classes.onlineStatus;
    } else if (status === STOPPED) {
      return classes.offlineStatus;
    } else if (status === OFFLINE) {
      return classes.unknownStatus;
    }
  };

  const pos = v.position;
  return (
    <ListItem classes={{ root: classes.root }} className={getBackgroundColor()}>
      <div
        className={classes.itemLeft}
        onClick={() => {
          if (width === "xs") {
            openCloseDrawer(!open);
          }
          v.isPopup = true;
          v.position = pos;
          getNearestLandmark2(v);
        }}
      >
        <ListItemText>{v.name}</ListItemText>
        {pos && pos.valid === false ? (
          <ListItemText
            classes={{
              root: classes.subheaderRoot,
              primary: classes.subheaderPrimary,
            }}
          >
            {`[No GPS Data, location may not be accurate]`}
          </ListItemText>
        ) : null}
        <ListItemText
          classes={{
            root: classes.subheaderRoot,
            primary: classes.subheaderPrimary,
          }}
        >
          {subheader(v, stop)}
        </ListItemText>
      </div>
      <div className={classes.itemRight}>
        <VehicleMenu
          vehicle={v}
          setMapView={setMapView}
          setLiveTrack={setLiveTrack}
          openCloseDrawer={openCloseDrawer}
          activateLiveTracking={activateLiveTracking}
          deactivateLiveTracking={deactivateLiveTracking}
          open={open}
          openStreetView={openStreetView}
        />
      </div>
    </ListItem>
  );
};

const mapStateToProps = (state, { v }) => {
  const id = v.id;
  const stopIdx = state.vehicles.stops.findIndex((s) => s.deviceId === id);
  const stop = state.vehicles.stops[stopIdx];

  return {
    open: state.settings.openDrawer,
    positions: state.vehicles.positions,
    stop: stopIdx > -1 ? stop : null,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setMapView,
    getNearestLandmark2,
    setLiveTrack,
    openCloseDrawer,
    activateLiveTracking,
    deactivateLiveTracking,
    openStreetView,
  })(withWidth()(Vehicle))
);
