import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { getUserGeofences } from "../../actions/geofenceAction";
import { openDialog } from "../../actions/dialogAction";

import { DIALOG_CONTENT } from "../../utils/Constants";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "flex-start",
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: "1px solid #ccc",
    "&:first-child": {
      borderTop: "1px solid #ccc",
    },
  },
}));

const UserMenu = withRouter(
  connect(null, { openDialog, getUserGeofences })((props) => {
    const { user } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleClick(event) {
      setAnchorEl(event.currentTarget);
    }

    function handleClose(to, view) {
      setAnchorEl(null);
    }

    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={`geofence-menu-${user.id}`}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => {
            handleClose();
          }}
        >
          <MenuItem
            onClick={() => {
              props.getUserGeofences(user.id, () => {
                props.openDialog(
                  DIALOG_CONTENT.userGeofence,
                  DIALOG_CONTENT.user,
                  { userId: user.id }
                );
              });
            }}
          >
            Geofence
          </MenuItem>
        </Menu>
      </div>
    );
  })
);

function User({ user }) {
  const classes = useStyles();
  return (
    <ListItem classes={{ root: classes.root }}>
      <ListItemText primary={user.name} />
      <UserMenu user={user} />
    </ListItem>
  );
}

export default User;
