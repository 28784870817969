import {
  SET_COMPANY,
  SET_FILTER,
  RESET_FILTER,
  FILTER_VEHICLES,
  SET_MAP_VIEW,
  OPEN_CLOSE_DRAWER
} from "./type";

export const setSettings = id => dispatch => {
  dispatch({ type: SET_COMPANY, payload: id });
};

export const setFilter = args => dispatch => {
  const { isFilter, keyword, by } = args;
  if (isFilter) {
    let filterBy = { name: false, status: false, company: false };
    switch (by) {
      case "name":
        filterBy.name = true;
        break;
      case "status":
        filterBy.status = true;
        break;
      case "company":
        filterBy.company = true;
        break;
      default:
        filterBy = { name: false, status: false, company: false };
    }
    filterBy.isMoving = args.isMoving ? args.isMoving : null;
    dispatch({
      type: SET_FILTER,
      payload: { keyword: keyword, filterBy }
    });
    dispatch({
      type: FILTER_VEHICLES,
      payload: args
    });
  } else {
    dispatch({
      type: RESET_FILTER,
      payload: null
    });
    dispatch({ type: FILTER_VEHICLES, payload: { isFilter: false } });
  }
};

export const setMapView = args => dispatch => {
  dispatch({ type: SET_MAP_VIEW, payload: args });
};

export const openCloseDrawer = args => dispatch => {
  dispatch({ type: OPEN_CLOSE_DRAWER, payload: args });
};
