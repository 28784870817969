import moment from "moment";
import {
  GET_VEHICLES,
  GET_POSITIONS,
  UPDATE_POSITION,
  UPDATE_VEHICLE,
  GET_VEHICLE,
  GET_TRIPS,
  ACTIVATE_LIVE_TRACKING,
  DEACTIVATE_LIVE_TRACKING,
  FILTER_VEHICLES,
  RESET_VEHICLES,
  GET_VEHICLE_STOPS,
  CLEAN_UP_TRIPS_AND_LIVE,
  GET_VEHICLES_STOPS
} from "../actions/type";
import {
  sortByTimeDesc,
  sortByStartTimeDesc,
  getGpxStatus
} from "../utils/MapUtils";
import { OFFLINE, ONLINE, MOVING, STOPPED, UNKNOWN } from "../utils/Constants";
import * as constStr from "../utils/Constants";

const initialState = {
  vehicles: [],
  filteredVehicles: [],
  positions: [],
  vehicle: {},
  vPositions: [],
  vStops: [],
  liveTrack: false,
  stops: [],
  features: [],
  vehicleCounts: {
    online: 0,
    offline: 0,
    unknown: 0,
    total: 0
  }
};

const updateVehiclePosition = (payload, vehicles) => {
  const vs = [...vehicles];

  for (let i = 0; i < payload.length; i++) {
    const index = vs.findIndex(v => v.id === payload[i].deviceId);
    const vhcl = {
      ...vs[index],
      position: payload[i],
      positionId: payload[i].id
    };
    vhcl.lastPositionUpdate = payload[i].fixTime;
    vhcl.lastUpdateInMinutes = moment().diff(payload[i].fixTime, "minutes");
    vhcl.gpxStatus = getGpxStatus(vhcl);
    vs[index] = vhcl;
  }

  return vs;
};

const updatePositions = (payload, positions) => {
  const ps = [...positions];
  let index = -1;
  if (payload.length <= 1) {
    index = ps.findIndex(p => p.deviceId === payload[0].deviceId);
    ps.splice(index, 1, payload[0]);

    return ps;
  } else {
    return payload;
  }
};

const updateStops = (payload, stops, positions) => {
  const allStops = [...stops];
  const ps = [...positions];
  const posIndex = ps.findIndex(pos => pos.deviceId === payload.id);
  const pos = ps[posIndex];

  const vStops = sortByStartTimeDesc(
    allStops.filter(s => payload.id === s.deviceId)
  );

  // Return all stops if position data is moving
  if (vStops.length < 1 && pos && pos.attributes.motion) {
    return allStops;
  }

  // Create new stop if position data is not moving and append it to all stops of the device
  if (vStops.length < 1 && pos && !pos.attributes.motion) {
    let stop = {
      deviceId: payload.id,
      startTime: payload.lastUpdate,
      endTime: payload.lastUpdate
    };
    return [...stops.filter(s => s.deviceId !== payload.id), stop];
  }

  // Update end time if device stop > 0
  if (
    vStops.length > 0 &&
    payload.attributes &&
    payload.attributes.motion &&
    payload.attributes.distance < constStr.DISTANCE_THRESHOLD &&
    payload.speed < constStr.SPEED_THRESHOLD
  ) {
    vStops[0].endTime = payload.lastUpdate;
  }

  // Update end time if device stop > 0 and if position data is movng
  if (vStops.length > 0 && payload.attributes && !payload.attributes.motion) {
    vStops[0].endTime = payload.lastUpdate;
  }

  // Update start time of stop
  if (
    pos &&
    pos.attributes &&
    !pos.attributes.motion &&
    payload.attributes &&
    payload.attributes.motion &&
    payload.attributes.distance < constStr.DISTANCE_THRESHOLD &&
    payload.speed < constStr.SPEED_THRESHOLD
  ) {
    vStops[0].startTime = payload.lastUpdate;
  }

  const stopsWithoutVStops = allStops.filter(s => payload.id !== s.deviceId);
  if (vStops.length > 0) {
    return [...stopsWithoutVStops, vStops[0]];
  } else {
    return allStops;
  }
};

const updateVehicle = (v, payload) => {
  let vehicles = [];

  for (let i = 0, len = v.length; i < len; i++) {
    let vehicle = { ...v[i] };
    if (v[i].id === payload[0].id) {
      vehicle = { ...v[i], ...payload[0] };
      vehicle.gpxStatus = getGpxStatus(vehicle);
      // vehicle.lastUpdateInMinutes = moment().diff(
      //   vehicle.lastUpdate,
      //   "minutes"
      // );
    }
    vehicles = [...vehicles, vehicle];
  }

  return vehicles;

  // return vehicles.map(v => {
  //   let vehicle = { ...v };

  //   if (v.id === payload[0].id) {
  //     vehicle = { ...v, ...payload[0] };
  //     vehicle.gpxStatus = getGpxStatus(vehicle);
  //     vehicle.lastUpdateInMinutes = moment().diff(vehicle.lastUpdate, "minutes");
  //   }
  //   return vehicle;
  // });
};

const generateFeatures = vehicles => {
  let features = [];

  for (let i = 0, len = vehicles.length; i < len; i++) {
    const { position } = vehicles[i];

    if (position) {
      let status = vehicles[i].gpxStatus;
      if (status === MOVING) {
        status = ONLINE;
      } else if (status === STOPPED) {
        status = OFFLINE;
      } else if (status === OFFLINE) {
        status = UNKNOWN;
      }
      const feature = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [position.longitude, position.latitude]
        },
        properties: {
          title: vehicles[i].name,
          icon: `map_pin_${status}_${vehicles[i].category}`,
          vid: vehicles[i].id
        }
      };

      features = [...features, feature];
    }
  }

  return features;
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLES:
      const vhcls = action.payload.map(vehicle => {
        let pos = state.positions.filter(pos => pos.deviceId === vehicle.id);
        const v = {
          ...vehicle,
          position: pos[0]
        };
        v.gpxStatus = getGpxStatus(v);
        return v;
      });
      return {
        ...state,
        vehicles: vhcls,
        filteredVehicles: vhcls
      };
    case GET_VEHICLE_STOPS:
      return {
        ...state,
        stops: sortByStartTimeDesc(action.payload)
      };
    case FILTER_VEHICLES:
      let filteredVehicles = [];

      if (action.payload.isFilter) {
        switch (action.payload.by) {
          case "name":
            filteredVehicles = state.vehicles.filter(v =>
              v.name.toLowerCase().includes(action.payload.keyword)
            );
            break;
          case "status":
            filteredVehicles = state.vehicles.filter(
              v => v.gpxStatus === action.payload.keyword
            );
            break;
          case "company":
            filteredVehicles = state.vehicles.filter(
              v => v.groupId === parseInt(action.payload.keyword)
            );
            break;
          default:
            return;
        }
      } else {
        filteredVehicles = state.vehicles;
      }
      return {
        ...state,
        filteredVehicles
      };
    case GET_POSITIONS:
      return {
        ...state,
        positions: action.payload
      };
    case UPDATE_POSITION: // Need to optimize
      const { liveTrack, vPositions, vehicle, positions } = state;
      let vPos = [...vPositions];
      if (
        liveTrack &&
        vehicle.id === action.payload[0].deviceId &&
        vPositions[0].id !== action.payload[0].id
      ) {
        vPos = [action.payload[0], ...vPositions];
      }
      const uVehicles = updateVehiclePosition(action.payload, state.vehicles);
      return {
        ...state,
        positions: updatePositions(action.payload, positions),
        vehicles: uVehicles,
        vehicleCounts: {
          online: uVehicles.filter(v => v.gpxStatus === constStr.MOVING).length,
          offline: uVehicles.filter(v => v.gpxStatus === constStr.STOPPED)
            .length,
          unknown: uVehicles.filter(v => v.gpxStatus === constStr.OFFLINE)
            .length,
          total: uVehicles.length
        },
        filteredVehicles: updateVehiclePosition(
          action.payload,
          state.filteredVehicles
        ),
        vPositions: vPos
      };
    case UPDATE_VEHICLE:
      const uvVehicle = updateVehicle(state.vehicles, action.payload);

      return {
        ...state,
        vehicles: uvVehicle,
        // filteredVehicles: uvfVehicle, // To optimize
        stops: updateStops(action.payload[0], state.stops, state.positions)
      };
    case GET_VEHICLE:
      return {
        ...state,
        vehicle: action.payload[0]
      };
    case GET_VEHICLES_STOPS:
      return {
        ...state,
        vStops: action.payload
      };
    case GET_TRIPS:
      return {
        ...state,
        vPositions: sortByTimeDesc(action.payload) //reduceArray(action.payload)
      };
    case DEACTIVATE_LIVE_TRACKING:
    case ACTIVATE_LIVE_TRACKING:
      return {
        ...state,
        liveTrack: action.payload
      };
    case CLEAN_UP_TRIPS_AND_LIVE:
      return {
        ...state,
        vPositions: [],
        vStops: [],
        vehicle: {},
        liveTrack: false
      };
    case RESET_VEHICLES:
      return {
        vehicles: [],
        filteredVehicles: [],
        positions: [],
        vehicle: {},
        vPositions: [],
        liveTrack: false,
        vehicleCounts: {
          onlinet: 0,
          offline: 0,
          unknown: 0,
          total: 0
        }
      };
    default:
      return state;
  }
}
