import { GET_GROUPS, SET_COMPANY, GET_VEHICLE_STOPS } from "./type";
import axios from "axios";
import moment from "moment";
import { getUTCDate } from "../utils/MapUtils";
import { API_GPX } from "../utils/Constants";

export const getGroups = (callback) => (dispatch) => {
  axios
    .get(`${API_GPX}/groups`)
    .then((res) => {
      if (res.data) {
        const ids = res.data.map((d) => d.id);
        const d = getUTCDate(moment().format("YYYY-MM-DD"));
        callback(ids[0]);
        axios
          .get(
            `${API_GPX}/reports/stops?${generateQueryString({
              ...d,
              ids,
            })}`
          )
          .then((stops) => {
            if (stops.status === 200) {
              dispatch({ type: GET_VEHICLE_STOPS, payload: stops.data });
            }
          })
          .catch((e) => console.log(e));
        dispatch({
          type: GET_GROUPS,
          payload: { groups: res.data, ids },
        });
      }
    })
    .catch((e) => console.log(e));
};

export const setGroup = (id) => (dispatch) => {
  dispatch({ type: SET_COMPANY, payload: id });
};

const generateQueryString = (data) => {
  let qString = `from=${data.from}&to=${data.to}`;
  for (let i = 0; i < data.ids.length; i++) {
    qString += `&groupId=${data.ids[i]}`;
  }

  return qString;
};

export const uploadGeos = () => {
  // console.log("Test");
  // devices.map(device => {
  //   axios
  //     .post(`${API_GPX}/devices`, device)
  //     .then(res => {
  //       if (res.status) {
  //         console.log(res.data);
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  //   return device;
  // });
};
