import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

import moment from "moment";
import { connect } from "react-redux";

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          borderRadius: 0,
        },
      },
    },
  });

const columns = [
  {
    name: "deviceName",
    label: "Device",
    options: {
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      filterType: "textField",
    },
  },
  {
    name: "geoA",
    label: "Geofence A",
    options: {
      filterType: "textField",
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
    },
  },
  {
    name: "geoAExitTime",
    label: "Geo. A Exit Time",
    options: {
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      filter: false,
    },
  },
  {
    name: "geoB",
    label: "Geofence B",
    options: {
      filterType: "textField",
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
    },
  },
  {
    name: "geoBEntryTime",
    label: "Geo. B Entry Time",
    options: {
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      filter: false,
    },
  },
  {
    name: "duration",
    label: "Duration",
    options: {
      filterType: "textField",
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
    },
  },
  {
    name: "distance",
    label: "Distance",
    options: {
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      filter: false,
    },
  },
];

const GeofenceP2P = (props) => {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={"Report - Geofence Point to Point"}
        data={props.report}
        columns={columns}
        options={{
          print: false,
          selectableRows: false,
          responsive: "scrollMaxHeight",
          downloadOptions: {
            filename: `GeofencePointToPoint_${moment().format(
              "YYYYMMDDHHmmss"
            )}.csv`,
          },
          textLabels: {
            pagination: {
              rowsPerPage: "Per Page",
            },
          },
        }}
      />
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  report: state.reports.geofenceP2P,
});

export default connect(mapStateToProps)(GeofenceP2P);
