import {
  GET_COMPLETE_LOGS,
  GET_OVERSPEED,
  GET_OVERSPEED_POSITIONS,
  DISPATCH_VEHICLES_TO_REPORT,
  DISPATCH_SELECTED_VEHICLES_TO_REPORT,
  GET_STOPS,
  GET_STOP_LOGS,
  RESET_REPORT_DATA,
  GET_SUMMARY_REPORT,
  GET_ALERTS,
  GET_ALERTS_POSITIONS,
  RESET_REPORTS,
  GET_GEOFENCE_ALERT,
  GET_GEOFENCE_REPORT,
} from "../actions/type";
import moment from "moment";

const initialState = {
  completeLogs: [],
  overspeed: [],
  stops: [],
  positions: [],
  vehicles: [],
  selectedVehicles: [],
  stopLogs: [],
  summaryReport: [],
  alerts: [],
  alertsPositionIds: [],
  alertsPositions: [],
  geofences: [],
  geofenceP2P: [],
};

const pushAddressOnOverspeed = (overspeeds, positions) => {
  const os = overspeeds.map((overspeed) => {
    const posIndex = positions.findIndex(
      (pos) => pos.id === overspeed.positionId
    );
    const pos = positions[posIndex];
    overspeed.address = pos.address;
    overspeed.latitude = pos.latitude;
    overspeed.longitude = pos.longitude;

    overspeed.date = moment(overspeed.serverTime).format("YYYY-MM-DD");
    overspeed.time = moment(overspeed.serverTime).format("HH:mm:ss");

    return overspeed;
  });

  return os;
};

const updateStopLogs = (logs) => {
  return logs.map((log) => {
    log.sDate = moment(log.startTime).format("YYYY-MM-DD");
    log.sTime = moment(log.startTime).format("HH:mm:ss");
    log.eDate = moment(log.endTime).format("YYYY-MM-DD");
    log.eTime = moment(log.endTime).format("HH:mm:ss");

    return log;
  });
};

const updateSummaryReport = (logs, vehicles) => {
  return logs.map((log) => {
    let vIdx = vehicles.findIndex((v) => v.id === log.deviceId);
    let v = vehicles[vIdx];
    let fuelConsumed = 0;
    const distance = `${(parseFloat(log.distance) / 1000).toFixed(2)}`;
    if (v.attributes && v.attributes.kmPerLiter) {
      fuelConsumed = distance / v.attributes.kmPerLiter;
    }

    log.maxSpeed = log.maxSpeed > 0 ? Math.round(log.maxSpeed * 1.852) : 0;
    log.averageSpeed = `${
      log.averageSpeed > 0 ? Math.round(log.averageSpeed * 1.852) : 0
    }`;
    log.distance = distance;
    log.fuelConsumed = fuelConsumed;
    return log;
  });
};

const f = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPLETE_LOGS:
      return {
        ...state,
        completeLogs: action.payload,
      };
    case GET_OVERSPEED:
      return {
        ...state,
        overspeed: action.payload,
      };
    case GET_OVERSPEED_POSITIONS:
      return {
        ...state,
        overspeed: pushAddressOnOverspeed(state.overspeed, action.payload),
        positions: action.payload,
      };
    case DISPATCH_VEHICLES_TO_REPORT:
      return {
        ...state,
        vehicles: action.payload,
      };
    case DISPATCH_SELECTED_VEHICLES_TO_REPORT:
      return {
        ...state,
        selectedVehicles: action.payload,
      };
    case GET_STOPS:
      return {
        ...state,
        stops: action.payload,
      };
    case GET_STOP_LOGS:
      return {
        ...state,
        stopLogs: updateStopLogs(action.payload),
      };
    case RESET_REPORT_DATA:
      return {
        ...state,
        completeLogs: [],
        overspeed: [],
        stops: [],
        stopLogs: [],
        selectedVehicles: [],
      };
    case GET_SUMMARY_REPORT:
      return {
        ...state,
        summaryReport: updateSummaryReport(action.payload, state.vehicles),
      };
    case GET_ALERTS:
      const { payload } = action;
      const gAlerts = payload.map((alert) => {
        const vIndex = state.vehicles.findIndex((v) => v.id === alert.deviceId);
        const vehicle = state.vehicles[vIndex];

        alert.vehicle = vehicle;
        alert.deviceName = vehicle.name;

        return alert;
      });

      return {
        ...state,
        alerts: gAlerts,
        alertsPositionIds: payload
          .filter((alert) => {
            let id = -1;
            if (alert.positionId) {
              id = alert.positionId;
            }
            return id;
          })
          .map((alert) => alert.positionId),
      };
    case GET_ALERTS_POSITIONS:
      const gpAlerts = [...state.alerts];
      const nAlerts = action.payload.map((pos) => {
        const alertIndex = gpAlerts.findIndex((a) => a.positionId === pos.id);
        const alert = gpAlerts[alertIndex];

        if (alert) {
          alert.latitude = pos.latitude;
          alert.longitude = pos.longitude;
          alert.position = pos;

          alert.address = pos.address;
        }
        return pos;
      });

      return {
        ...state,
        alertsPositions: [...state.alertsPositions, ...nAlerts],
        alerts: gpAlerts,
      };
    case GET_GEOFENCE_ALERT:
      return {
        ...state,
        geofenceP2P: action.payload,
      };
    case GET_GEOFENCE_REPORT:
      return {
        ...state,
        geofences: action.payload.map((geo) => {
          if (geo.type === "geofenceEnter") geo.gpxType = "Geofence Enter";
          else geo.gpxType = "Geofence Exit";

          return geo;
        }),
      };
    case RESET_REPORTS:
      return {
        completeLogs: [],
        overspeed: [],
        stops: [],
        positions: [],
        vehicles: [],
        selectedVehicles: [],
        stopLogs: [],
        summaryReport: [],
        alerts: [],
        alertsPositionIds: [],
        alertsPositions: [],
      };
    default:
      return state;
  }
};

export default f;
