import {
  SET_COMPANY,
  SET_VIEWPORT,
  SET_IS_ADMIN,
  SET_FILTER,
  RESET_FILTER,
  SET_MAP_VIEW,
  OPEN_CLOSE_DRAWER,
  RESET_SETTINGS
} from "../actions/type";

const initialState = {
  group: 0,
  center: [],
  viewport: {
    center: {
      lat: 0,
      lng: 0
    },
    zoom: 15
  },
  isAdmin: false,
  isMonitoring: false,
  isFilter: false,
  filterBy: {
    name: false,
    status: false,
    company: false
  },
  filterKeyword: "",
  mapView: 1,
  openDrawer: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        group: parseInt(action.payload)
      };
    case SET_VIEWPORT:
      return {
        ...state,
        viewport: action.payload
      };
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload.isAdmin,
        isMonitoring: action.payload.isMonitoring
      };
    case SET_FILTER:
      return {
        ...state,
        isFilter: true,
        filterBy: action.payload.filterBy,
        filterKeyword: action.payload.keyword
      };
    case RESET_FILTER:
      return {
        ...state,
        isFilter: false,
        filterBy: {
          name: false,
          status: false,
          company: false
        },
        filterKeyword: ""
      };
    case SET_MAP_VIEW:
      return {
        ...state,
        mapView: action.payload
      };
    case OPEN_CLOSE_DRAWER:
      return {
        ...state,
        openDrawer: action.payload
      };
    case RESET_SETTINGS:
      return {
        group: 0,
        viewport: {
          center: {
            lat: 0,
            lng: 0
          },
          zoom: 15
        },
        isAdmin: false,
        isMonitoring: false,
        isFilter: false,
        filterBy: {
          name: false,
          status: false,
          company: false
        },
        filterKeyword: "",
        mapView: 1,
        openDrawer: true
      };
    default:
      return state;
  }
}
