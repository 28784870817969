import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";

import User from "./User";
import Spinner from "../Layouts/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  inputClassLabel: {
    transform: "translate(14px, 10px) scale(1)",
  },
  inputClass: {
    padding: ".5rem",
  },
  w100: {
    width: "100%",
    marginTop: "0",
  },
}));

function UserList(props) {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    if (!keyword) {
      setUsers(props.users);
    } else {
      setUsers(
        props.users.filter((user) =>
          user.name.toLowerCase().includes(keyword.toLowerCase())
        )
      );
    }
  }, [keyword]);

  return users.length > 0 ? (
    <React.Fragment>
      <TextField
        id="txtSearchUser"
        label="Search User ..."
        InputLabelProps={{
          classes: {
            root: classes.inputClassLabel,
          },
        }}
        InputProps={{
          classes: { input: classes.inputClass },
        }}
        className={classes.w100}
        margin="normal"
        variant="outlined"
        onChange={(e) => {
          setKeyword(e.target.value);
        }}
      />
      <List component="nav" aria-label="main mailbox folders">
        {users.map((user) => (
          <User key={user.id} user={user} />
        ))}
      </List>
    </React.Fragment>
  ) : (
    <Spinner />
  );
}

const mapStateToProps = (state) => ({
  users: state.user.users,
});

export default connect(mapStateToProps, {})(UserList);
