import React from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import moment from "moment";

import { connect } from "react-redux";

const eventsColumns = [
  {
    name: "date",
    label: "Date"
  },
  {
    name: "time",
    label: "Time"
  },
  {
    name: "typeString",
    label: "Type"
  },
  {
    name: "deviceName",
    label: "Device"
  }
];

const eventsWithAddressColumns = [
  {
    name: "date",
    label: "Date"
  },
  {
    name: "time",
    label: "Time"
  },
  {
    name: "typeString",
    label: "Type"
  },
  {
    name: "deviceName",
    label: "Device"
  },
  {
    name: "landmark",
    label: "Landmark"
  },
  {
    name: "latitude",
    label: "Latitude"
  },
  {
    name: "longitude",
    label: "Longitude"
  }
];

const EventsLogs = props => {
  let cols = eventsWithAddressColumns;

  return (
    <React.Fragment>
      <MUIDataTable
        title={"Report - Alerts"}
        data={props.alerts}
        columns={cols}
        options={{
          print: false,
          selectableRows: false,
          downloadOptions: {
            filename: "Alerts_" + moment().format("YYYYMMDDHHmmss") + ".csv"
          }
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  alerts: state.reports.alerts
});

export default connect(mapStateToProps)(EventsLogs);
