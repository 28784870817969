import { Component, memo } from "react";
import { connect } from "react-redux";
import { updateEvents } from "./actions/socketActions";
import {
  updateVehiclePosition,
  updateVehicle,
} from "./actions/vehiclesActions";

class SocketController extends Component {
  socket() {
    const socket = new WebSocket("wss://gpx.cloudph.net/api/socket");
    return socket;
  }

  connectSocket = (isOpen) => {
    const socket = this.socket();
    socket.onclose = () => {
      window.location.reload();
    };

    if (isOpen) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.devices) {
          // this.props.dispatch(updateDevices(data.devices));
          this.props.dispatch(updateVehicle(data.devices));
        }
        if (data.positions) {
          // this.props.dispatch(updatePositions(data.positions));
          this.props.dispatch(updateVehiclePosition(data.positions));
        }
        if (data.events) {
          this.props.dispatch(updateEvents(data.events));
        }
      };
    }

    socket.onopen = (event) => {
      // console.log("Opened");
    };
  };

  componentDidMount() {
    this.connectSocket(this.props.isOpen);
  }

  componentWillMount() {
    this.socket().close();
  }

  render() {
    return null;
  }
}

export default memo(connect()(SocketController));
