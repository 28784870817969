import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import DateMomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {
  dispatchVehiclesToReports,
  dispatchSelectedVehiclesToReport,
} from "../../actions/reportsActions";

const style = (theme) => ({
  formControl: {
    marginTop: "16px",
  },
  inputSelect: {
    padding: ".5rem",
  },
  inputSelectLabel: {
    transform: "translate(14px, 10px) scale(1)",
  },
  reportForm: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    padding: ".25rem",
    borderRadius: 0,
  },
  button: {
    marginTop: "16px",
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  getContentAnchorEl: () => null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 281,
    },
  },
};

class ReportFilter extends Component {
  state = {
    dateFrom: moment().format("YYYY-MM-DD"),
    dateTo: moment().format("YYYY-MM-DD"),
    type: 1,
    vehicles: [],
    selectedVehicles: [],
    selectedVehiclesRaw: [],
    company: 0,
    companies: [],
    geofences: [],
    isSelectAll: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedVehicles !== this.state.selectedVehicles) {
      this.props.dispatchVehiclesToReports(this.state.selectedVehiclesRaw);
      this.props.dispatchSelectedVehiclesToReport(this.state.selectedVehicles);
    }
  }

  componentDidMount() {
    this.setState({
      companies: this.props.company,
      vehicles: this.props.vehicles,
      geofences: this.props.geofences,
    });

    this.props.handleSetType({
      target: { value: this.state.type, name: "type" },
    });
  }
  handleSelectAllVehicles = () => {
    const { vehicles, company } = this.state;
    const fVehicles =
      parseInt(company) === 0
        ? vehicles
        : vehicles.filter((v) => v.groupId === parseInt(company));
    let ids = [];
    for (let i = 0; i < fVehicles.length; i++) {
      ids.push(fVehicles[i].id);
    }
    return ids;
  };
  handleOnSelectChangeInput = (e, f) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSelectAllOnChange = (e) => {
    this.setState({
      isSelectAll: e.target.checked,
      selectedVehicles: e.target.checked ? this.handleSelectAllVehicles() : [],
    });
  };
  handleOnFromDateChange = (d) => {
    this.setState({ dateFrom: moment(d).format("YYYY-MM-DD") });
  };
  handleOnToDateChange = (d) => {
    this.setState({ dateTo: moment(d).format("YYYY-MM-DD") });
  };
  handleOnChangeVehicles = (event) => {
    const vehicles = this.state.vehicles;
    const ids = event.target.value;
    let v = [];
    if (ids.length > 0) {
      for (let i = 0; i < ids.length; i++) {
        let id = ids[i];
        const idx = vehicles.findIndex((v) => v.id === id);
        if (idx > -1) {
          v.push(vehicles[idx]);
        }
      }
    }
    this.setState({ selectedVehicles: ids, selectedVehiclesRaw: v });
  };
  handleRenderVehiclesSelectValue = (selected) => {
    const { vehicles } = this.state;
    let selectedValues = [];

    for (let i = 0; i <= selectedValues.length; i++) {
      const vIndex = vehicles.findIndex(
        (vehicle) => vehicle.id === selected[i]
      );
      const v = vehicles[vIndex];
      if (v) {
        selectedValues.push(v.name);
      }
    }
    return selectedValues.join(", ");
  };

  render() {
    const { classes, handleOnShowReport, handleSetType } = this.props;
    const {
      dateFrom,
      dateTo,
      type,
      company,
      companies,
      selectedVehicles,
      vehicles,
      isSelectAll,
      geofences,
    } = this.state;

    const vs =
      parseInt(company) === 0
        ? vehicles
        : vehicles.filter((v) => v.groupId === parseInt(company));

    return (
      <Paper className={classes.reportForm}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            htmlFor="outlined-type-native-simple"
            classes={{ outlined: classes.inputSelectLabel }}
          >
            Company ...
          </InputLabel>
          <Select
            native
            value={company}
            name="company"
            input={
              <OutlinedInput
                name="type"
                labelWidth={90}
                id="outlined-type-native-simple"
              />
            }
            classes={{ select: classes.inputSelect }}
            onChange={this.handleOnSelectChangeInput}
          >
            <option value="0">All ...</option>
            {companies.map((c) => {
              return (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            htmlFor="outlined-type-native-simple"
            classes={{ outlined: classes.inputSelectLabel }}
          >
            Report Type ...
          </InputLabel>
          <Select
            native
            value={type}
            name="type"
            input={
              <OutlinedInput
                name="type"
                labelWidth={105}
                id="outlined-type-native-simple"
              />
            }
            classes={{ select: classes.inputSelect }}
            onChange={(e) => {
              this.handleOnSelectChangeInput(e);
              handleSetType(e);
            }}
          >
            <option value="1">Complete Logs</option>
            <option value="4">Stop Logs</option>
            <option value="5">Summary Report</option>
            <option value="7">Gofence P2P</option>
            <option value="8">Gofence</option>
            {/* <option value="6">Alerts</option> */}
          </Select>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateMomentUtils}>
          <DatePicker
            margin="normal"
            label="Date From ..."
            variant="outlined"
            inputlabelprops={{
              classes: { outlined: classes.inputSelectLabel },
            }}
            InputProps={{ classes: { input: classes.inputSelect } }}
            format="YYYY-MM-DD"
            value={dateFrom}
            onChange={this.handleOnFromDateChange}
            name="dateFrom"
            autoOk={true}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateMomentUtils}>
          <DatePicker
            margin="normal"
            label="Date To ..."
            variant="outlined"
            inputlabelprops={{
              classes: { outlined: classes.inputSelectLabel },
            }}
            InputProps={{
              classes: {
                input: classes.inputSelect,
              },
            }}
            format="YYYY-MM-DD"
            value={dateTo}
            onChange={this.handleOnToDateChange}
            name="dateTo"
            autoOk={true}
          />
        </MuiPickersUtilsProvider>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            htmlFor="outlined-select-multiple-checkbox"
            classes={{ outlined: classes.inputSelectLabel }}
          >
            Vehicles ...
          </InputLabel>
          <Select
            multiple
            value={selectedVehicles}
            onChange={this.handleOnChangeVehicles}
            input={
              <OutlinedInput
                name="type"
                labelWidth={80}
                id="outlined-select-multiple-checkbox"
              />
            }
            renderValue={this.handleRenderVehiclesSelectValue}
            MenuProps={MenuProps}
            classes={{ select: classes.inputSelect }}
          >
            {vs.map((vehicle) => (
              <MenuItem key={vehicle.id} value={vehicle.id}>
                {vehicle.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSelectAll}
              onChange={this.handleSelectAllOnChange}
              name="isSelectAll"
            />
          }
          label="Select All"
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            fullWidth={true}
            onClick={() => {
              const dispatchedVehicles = selectedVehicles
                .filter((i) => i > 0)
                .map((id) => {
                  const vIndex = vehicles.findIndex((v) => v.id === id);
                  return vehicles[vIndex];
                });
              handleOnShowReport({
                type: parseInt(type),
                from: dateFrom,
                to: dateTo,
                devices: selectedVehicles.filter((i) => i > 0),
                vehicles: dispatchedVehicles,
                geofences,
              });
            }}
          >
            SHOW
          </Button>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  company: state.groups.groups,
  vehicles: state.vehicles.vehicles,
  geofences: state.geofences.geofences,
});

ReportFilter.propTypes = {
  dispatchVehiclesToReports: PropTypes.func.isRequired,
  dispatchSelectedVehiclesToReport: PropTypes.func.isRequired,
  handleSetType: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  dispatchVehiclesToReports,
  dispatchSelectedVehiclesToReport,
})(withStyles(style)(ReportFilter));
