export const GET_SERVER = "GET_SERVER";

// Socket
export const UPDATE_DEVICES = "UPDATE_DEVICES";
export const UPDATE_POSITIONS = "UPDATE_POSITIONS";
export const UPDATE_EVENTS = "UPDATE_EVENTS";
export const CLOSE_EVENT = "CLOSE_EVENT";

// User
export const GET_USER = "GET_USER";
export const GET_USERS = "GET_USERS";
export const LOGOUT_USER = "LOGOUT_USER";

// Vehicles
export const GET_VEHICLES = "GET_VEHICLES";
export const GET_VEHICLE = "GET_VEHICLE";
export const GET_POSITIONS = "GET_POSITIONS";
export const UPDATE_POSITION = "UPDATE_POSITION";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const GET_TRIPS = "GET_TRIPS";
export const ACTIVATE_LIVE_TRACKING = "ACTIVATE_LIVE_TRACKING";
export const DEACTIVATE_LIVE_TRACKING = "DEACTIVATE_LIVE_TRACKING";
export const RESET_VPOSITIONS = "RESET_VPOSITIONS";
export const FILTER_VEHICLES = "FILTER_VEHICLES";
export const GET_VEHICLE_STOPS = "GET_VEHICLE_STOPS";
export const CLEAN_UP_TRIPS_AND_LIVE = "CLEAN_UP_TRIPS_AND_LIVE";
export const GET_VEHICLES_STOPS = "GET_VEHICLES_STOPS";

// Geofence
export const GET_GEOFENCES = "GET_GEOFENCES";
export const GET_USER_GEOFENCES = "GET_USER_GEOFENCES";
export const GET_GEOFENCE = "GET_GEOFENCE";
export const UPDATE_GEOFENCE = "UPDATE_GEOFENCE";
export const SAVE_GEOFENCE = "SAVE_GEOFENCE";
export const DELETE_GEOFENCE = "DELETE_GEOFENCE";

// Groups
export const GET_GROUPS = "GET_GROUPS";

// Settings
export const SET_COMPANY = "SET_COMPANY";
export const SET_VIEWPORT = "SET_VIEWPORT";
export const SET_IS_ADMIN = "SET_IS_ADMIN";
export const SET_FILTER = "SET_FILTER";
export const RESET_FILTER = "RESET_FILTER";
export const SET_MAP_VIEW = "SET_MAP_VIEW";
export const OPEN_CLOSE_DRAWER = "OPEN_CLOSE_DRAWER";

// Reports
export const GET_COMPLETE_LOGS = "GET_COMPLETE_LOGS";
export const GET_OVERSPEED = "GET_OVERSPEED";
export const GET_OVERSPEED_POSITIONS = "GET_OVERSPEED_POSITIONS";
export const DISPATCH_VEHICLES_TO_REPORT = "DISPATCH_VEHICLES_TO_REPORT";
export const DISPATCH_SELECTED_VEHICLES_TO_REPORT =
  "DISPATCH_SELECTED_VEHICLES_TO_REPORT";
export const GET_STOPS = "GET_STOPS";
export const RESET_REPORT_DATA = "RESET_REPORT_DATA";
export const GET_STOP_LOGS = "GET_STOP_LOGS";
export const GET_SUMMARY_REPORT = "GET_SUMMARY_REPORT";
export const GET_ALERTS = "GET_ALERTS";
export const GET_ALERTS_POSITIONS = "GET_ALERTS_POSITIONS";
export const GET_GEOFENCE_ALERT = "GET_GEOFENCE_ALERT";
export const GET_GEOFENCE_REPORT = "GET_GEOFENCE_REPORT";

// MAP
export const SET_POPUP = "SET_POPUP";
export const SET_MAP_CENTER = "SET_MAP_CENTER";
export const SET_LIVE_TRACK = "SET_LIVE_TRACK";

// REPORT WEB WORKER
export const GENERATE_COMPLETE_LOGS = "GENERATE_COMPLETE_LOGS";
export const RESPONSE_COMPLETE_LOGS = "RESPONSE_COMPLETE_LOGS";
export const GENERATE_OVERSPEED = "GENERATE_OVERSPEED";
export const RESPONSE_OVERSPEED = "RESPONSE_OVERSPEED";
export const GENERATE_GEOFENCE_P2P = "GENERATE_GEOFENCE_P2P";
export const RESPONSE_GENERATE_GEOFENCE_P2P = "GENERATE_GEOFENCE_P2P";
export const GENERATE_STOP_LOGS = "GENERATE_STOP_LOGS";
export const RESPONSE_GENERATE_STOP_LOGS = "RESPONSE_GENERATE_STOP_LOGS";

// MAP WEB WORKER
export const REQ_UPDATE_VEHICLE = "REQ_UPDATE_VEHICLE";
export const RES_UPDATED_VEHICLE = "RES_UPDATED_VEHICLE";
export const REQ_UPDATE_STOPS = "REQ_UPDATE_VEHICLE";
export const RES_UPDATED_STOPS = "RES_UPDATED_VEHICLE";

// RESET
export const RESET_SERVER = "RESET_SERVER";
export const RESET_GROUPS = "RESET_GROUPS";
export const RESET_VEHICLES = "RESET_VEHICLES";
export const RESET_REPORTS = "RESET_REPORTS";
export const RESET_SETTINGS = "RESET_SETTINGS";
export const RESET_MAP = "RESET_MAP";

// DIALOG
export const OPEN_DIALOG = "OPEN_DIALOG";
export const CLOSE_DIALOG = "CLOSE_DIALOG";
export const SET_CONTENT_DIALOG = "SET_CONTENT_DIALOG";
export const SET_LOADER_OPEN = "SET_LOADER_OPEN";
export const SET_LOADER_CLOSE = "SET_LOADER_CLOSE";
export const SET_STREETVIEW_OPEN = "SET_STREETVIEW_OPEN";
export const SET_STREETVIEW_CLOSE = "SET_STREETVIEW_CLOSE";
