import React from "react";
import { connect } from "react-redux";

import Spinner from "./Spinner";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

function DialogUtilLoader({ open }) {
  return (
    <Dialog
      maxWidth="md"
      open={open}
      aria-labelledby="responsive-dialog-title"
      disableBackdropClick={true}
    >
      <React.Fragment>
        <DialogContent>
          <Spinner />
          <h4>Loading ... please wait</h4>
        </DialogContent>
      </React.Fragment>
    </Dialog>
  );
}

const mapStateToProp = (state) => ({
  open: state.dialog.isLoaderOpen,
});

export default connect(mapStateToProp, {})(DialogUtilLoader);
